
import { defineComponent, reactive, computed, ref, Ref, onMounted } from 'vue'
import { maska } from 'maska'

import useClickOutside from '@/common/composable/useClickOutside'

// Constants
import { PHONE_OPTIONS } from '@/core/constants/Phone.constants'

// Types
import { PhoneOption } from '@/core/types/Phone.types'

// Helpers
import { detectOptionByPhoneNumber } from '@/common/helpers'

export default defineComponent({
    directives: {
        maska,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const KZ_INDEX = PHONE_OPTIONS.findIndex((phone_option) => phone_option.code === 'KZ')

        const state = reactive({
            country_option: PHONE_OPTIONS[KZ_INDEX],
            is_list_opened: false,
            search_param: null as string | null,
        })

        const closeList = () => {
            state.is_list_opened = false
        }

        const componentref = ref(null) as Ref<HTMLElement | null>
        useClickOutside(componentref, closeList)

        const toggleList = () => {
            if (!state.is_list_opened) {
                if (props.disabled) {
                    return
                }
            }

            state.is_list_opened = !state.is_list_opened
        }

        const setCountryOption = (option: PhoneOption) => {
            state.country_option = option
            state.search_param = null
            closeList()
        }

        const initializeCountry = () => {
            if (!props.value) return

            const countryOption = detectOptionByPhoneNumber(props.value as string)
            if (!countryOption) return

            setCountryOption(countryOption)
        }

        onMounted(() => {
            setTimeout(initializeCountry, 100)
        })

        const onInput = (event: InputEvent) => {
            const target = event.target as HTMLInputElement
            const value = target.value

            emit('update:value', value)
        }

        const filteredOptions = computed(() => {
            if (!state.search_param) return PHONE_OPTIONS

            return PHONE_OPTIONS.filter((phone_option) =>
                phone_option.country
                    .toUpperCase()
                    .includes((state.search_param as string).toUpperCase())
            )
        })

        return {
            PHONE_OPTIONS,
            state,
            componentref,
            onInput,
            setCountryOption,
            toggleList,
            filteredOptions,
        }
    },
})
