import MapsI from '../interfaces/Maps.interface'
import mapsService from '../services/Maps.service'
import {
    GisFormattedListItem,
    GisFormattedResponseT,
    YandexFormattedListItem,
    YandexFormattedResponseT,
} from '../types/Map.types'

class MapsRealisation implements MapsI {
    async getYandexAdressByCoords(coords: number[]): Promise<YandexFormattedResponseT | null> {
        const adressResponse = await mapsService.getYandexAdressByCoords(coords)
        return adressResponse
    }

    async getYandexCoordsByAddress(address: string): Promise<number[] | null> {
        const coordsResponse = await mapsService.getYandexCoordsByAddress(address)
        return coordsResponse
    }

    async getListByAddress(address: string): Promise<YandexFormattedListItem[]> {
        const listResponse = await mapsService.getListByAddress(address)
        return listResponse
    }

    async geocodeAddressListByGis(address: string): Promise<GisFormattedListItem[]> {
        const gisResponse = await mapsService.geocodeAddressListByGis(address)
        return gisResponse
    }

    async geocodeAddressByGisCoords(coords: number[]): Promise<GisFormattedResponseT | null> {
        const gisResponse = await mapsService.geocodeAddressByGisCoords(coords)
        return gisResponse
    }
}

export default MapsRealisation
