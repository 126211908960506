import { CitiesRepository } from '../repositories/Cities.repository'
import { CityFromResponseT, CityT } from '../types/common.types'
import { CityFromResponse } from '../types/Responses/Cities.responses'

const citiesRepository = new CitiesRepository()

const citiesService = {
    async getAllCities(): Promise<CityT[]> {
        const citiesResponse = await citiesRepository.getAllCities()

        const formattedCities = citiesResponse.data.data.map((city: CityFromResponse) => {
            return {
                id: city.id,
                name: city.name,
                value: city.id,
                latitude: city.latitude,
                longitude: city.longitude,
                coordinates: city.coordinates
            }
        })

        return formattedCities
    },

    async getCitiesByCityId(city_id: number): Promise<CityT[]> {
        const citiesByCityIdResponse = await citiesRepository.getCitiesByCityId(city_id)

        const formattedCities = citiesByCityIdResponse.data.data.map((city: CityFromResponseT) => {
            return {
                id: city.id,
                name: city.name,
                value: city.id,
                latitude: city.latitude,
                longitude: city.longitude,
                coordinates: city.coordinates
            }
        })

        return formattedCities
    },
}

export default citiesService
