
import { computed, defineComponent, onMounted, reactive, Ref, ref, toRaw } from 'vue'

// Components
import SInput from '@/common/components/SInput/index.vue'
import SPhoneNumber from '@/common/components/SPhoneNumber/index.vue'

import STextArea from '@/common/components/STextArea/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

// Map Components
import SMap from '@/common/components/SMap/index.vue'
import SMapSearch from '@/common/components/SMapSearch/index.vue'

// Icons
import iClose from '@/assets/icons/Close.svg'
import iSearch from '@/assets/icons/Search.svg'
import iPerson from '@/assets/icons/Person.svg'
// import iPhone from '@/assets/icons/Phone.svg'
import iLocation from '@/assets/icons/Location.svg'
import iDots from '@/assets/icons/Dots.svg'
import iLattice from '@/assets/icons/Lattice.svg'
import iComment from '@/assets/icons/Comment.svg'

// Types
import { NewReceiverCreateState, ReceiverCreatePayload } from '@/core/types/Order.types'
import { CityT, OptionT } from '@/core/types/common.types'
import { WarehouseT } from '@/core/types/Responses/Warehouses.responses'
import { MappedPVZT, PVZT } from '@/core/types/PVZ.types'
import { MapSearchResultT } from '@/core/types/Map.types'

type AddressStateSetTypeT = {
    address: string | null
    street: string | null
    house: string | null
    latitude: number
    longitude: number
    is_warehouse: boolean
    is_pvz: boolean
    pvz_name: string | null
    warehouse_id: number | null
    pvz_id: number | null
    is_finded: boolean
}

// Map Constants
import { MAP_KEYS } from '@/core/constants/Map.constants'

// Realisations
import MapsRealisation from '@/core/realisations/Maps.realisation'
import { WarehousesRealisation } from '@/core/realisations/Warehouses.realisation'
import { PVZRealisation } from '@/core/realisations/PVZ.realisation'

// Constants
const DELIVERY_TYPES = [
    {
        id: 1,
        name: 'Доставка курьером',
        value: 1,
    },
    // {
    //     id: 2,
    //     name: 'Забрать из Пункта выдачи заказов',
    //     value: 2,
    // },
    {
        id: 3,
        name: 'Самовывоз со склада Spark',
        value: 3,
    },
]

// Validation
import useVuelidate from '@vuelidate/core'
import { CreateSenderValidation } from '@/modules/CreateOrder/validations/CreateSender.validation'

//
import {
    useMembersActions,
    isCREATE,
    isUPDATE,
    receiverCities,
    isReceiverActionsLoading,
} from '@/modules/CreateOrder/composable/useMembersActions'

import { useMaska } from '@/common/composable/useMaska'

export default defineComponent({
    components: {
        's-input': SInput,
        's-phone-number': SPhoneNumber,
        //
        's-textarea': STextArea,
        's-button': SButton,
        's-loader': SLoader,
        //
        's-map': SMap,
        's-map-search': SMapSearch,
        //
        'icon-close': iClose,
        'icon-search': iSearch,
        'icon-person': iPerson,
        // 'icon-phone': iPhone,
        'icon-location': iLocation,
        'icon-dots': iDots,
        'icon-lattice': iLattice,
        'icon-comment': iComment,
    },
    props: {
        isLTL: {
            type: Boolean,
            default: false,
        },
        isFTL: {
            type: Boolean,
            default: false,
        },
        isExpress: {
            type: Boolean,
            default: false,
        },
        //
        senderCityId: {
            type: Number,
            default: null,
        },
        status: {
            type: Number,
            default: null,
        },
    },
    emits: ['close', 'create', 'update'],
    setup(props, context) {
        // api's
        const warehousesAPI = new WarehousesRealisation()
        const pvzAPI = new PVZRealisation()

        const { getReceiverActionStateRaw, loadReceiverCities } = useMembersActions()

        // Statements
        const delivery_type: Ref<OptionT<any> | null> = ref(null)

        const state: NewReceiverCreateState = reactive({
            title: null,
            full_name: null,
            phone: null,
            additional_phone: null,
            city: null,
            full_address: null,
            latitude: null,
            longitude: null,
            index: null,
            office: null,
            comment: null,
            street: null,
            house: null,
            warehouse_id: null,
            distribution_center_id: null,
        })

        const address_state = reactive({
            is_loading: false,
            is_failed: false,
            is_finded: false,
            address: null as string | null,
            street: null as string | null,
            house: null as string | null,
            latitude: 0,
            longitude: 0,
            is_warehouse: false,
            is_pvz: false,
            pvz_name: null as string | null,
            warehouse_id: null as number | null,
            pvz_id: null as number | null,
        })

        const warehouses_state = reactive({
            is_loading: false,
            is_failed: false,
            warehouse: null as WarehouseT | null,
        })

        const pvz_state = reactive({
            is_loading: false,
            is_failed: false,
            list: [] as MappedPVZT[],
            pvz: null as MappedPVZT | null,
        })

        // Statement Validation
        const v$ = useVuelidate(CreateSenderValidation, state)

        const map_state = reactive({
            opened: false,
            instance: null,
            is_yandex: true,
            is_gis: false,
            is_google: false,
        })

        // Load functions
        const loadPVZ = async (city_id: number) => {
            try {
                pvz_state.is_loading = true

                const response = await pvzAPI.getList({ page: 1, cityId: city_id })
                pvz_state.list = response.data.map((pvz) => {
                    return {
                        ...pvz,
                        value: pvz.id,
                    }
                })

                pvz_state.is_failed = false
            } catch (error) {
                console.error(error)
                pvz_state.is_failed = true
            } finally {
                pvz_state.is_loading = false
            }
        }

        const loadWarehouse = async (city_id: number) => {
            if (!props.isLTL) return

            try {
                warehouses_state.is_loading = true

                const response = await warehousesAPI.getByCityId(city_id)
                warehouses_state.warehouse = response.data

                if (delivery_type.value && delivery_type.value.value === 3) {
                    state.street = warehouses_state.warehouse.street
                    state.house = warehouses_state.warehouse.house
                    state.latitude = Number(warehouses_state.warehouse.latitude)
                    state.longitude = Number(warehouses_state.warehouse.longitude)
                    state.full_address = warehouses_state.warehouse.full_address
                    state.index = warehouses_state.warehouse.index
                    state.office = warehouses_state.warehouse.office
                    state.warehouse_id = warehouses_state.warehouse.id
                }

                warehouses_state.is_failed = false
            } catch (error) {
                console.error(error)
                warehouses_state.is_failed = true
            } finally {
                warehouses_state.is_loading = false
            }
        }

        const onCityChange = async (city: CityT | null) => {
            state.distribution_center_id = null

            if (!city) {
                warehouses_state.warehouse = null

                state.full_address = null
                state.latitude = null
                state.longitude = null
                state.warehouse_id = null

                return
            }

            loadWarehouse(city.value)
            loadPVZ(city.value)
        }

        const onDeliveryTypeChange = (type: OptionT<number> | null) => {
            state.street = null
            state.house = null
            state.latitude = null
            state.longitude = null
            state.full_address = null
            state.index = null
            state.office = null
            state.warehouse_id = null

            if (!type) return

            if (type.value === 3 && warehouses_state.warehouse) {
                state.street = warehouses_state.warehouse.street
                state.house = warehouses_state.warehouse.house
                state.latitude = Number(warehouses_state.warehouse.latitude)
                state.longitude = Number(warehouses_state.warehouse.longitude)
                state.full_address = warehouses_state.warehouse.full_address
                state.index = warehouses_state.warehouse.index
                state.office = warehouses_state.warehouse.office
                state.warehouse_id = warehouses_state.warehouse.id
            }
        }

        // Emits
        const emitClose = () => {
            context.emit('close')
        }

        // Map Functions
        const openMap = () => {
            v$.value.city.$touch()
            if (v$.value.city.$invalid) return

            map_state.opened = true

            if (map_state.instance) {
                getCoordsByAddressYandex()
            }
        }

        const closeMap = () => {
            map_state.opened = false
        }

        const setAddressState = ({
            address,
            latitude,
            longitude,
            street,
            house,
            pvz_name,
            is_pvz,
            pvz_id,
            warehouse_id,
            is_warehouse,
            is_finded,
        }: AddressStateSetTypeT) => {
            address_state.address = address
            address_state.latitude = latitude
            address_state.longitude = longitude
            address_state.street = street
            address_state.house = house

            address_state.pvz_name = pvz_name
            address_state.is_pvz = is_pvz
            address_state.pvz_id = pvz_id

            address_state.is_warehouse = is_warehouse
            address_state.warehouse_id = warehouse_id

            address_state.is_finded = is_finded
        }

        const resetAddressState = () => {
            setAddressState({
                address: null,
                latitude: 0,
                longitude: 0,
                street: null,
                house: null,
                pvz_name: null,
                is_pvz: false,
                pvz_id: null,
                warehouse_id: null,
                is_warehouse: false,
                is_finded: false,
            })
        }

        const setMapCenter = (coordinates: number[]) => {
            // @ts-ignore
            map_state.instance.panTo.bind(toRaw(map_state.instance))(coordinates)
        }

        const clearClickedPlacemark = () => {
            const is_clicked_placemark_setted =
                address_state.is_finded && !address_state.is_warehouse && !address_state.is_pvz

            if (is_clicked_placemark_setted) {
                // @ts-ignore
                const geo_objects_length = map_state.instance.geoObjects.getLength.bind(
                    // @ts-ignore
                    toRaw(map_state.instance.geoObjects)
                )()

                // @ts-ignore
                map_state.instance.geoObjects.splice.bind(toRaw(map_state.instance.geoObjects))(
                    geo_objects_length - 1,
                    1
                )
            }
        }

        const onPVZMarkClick = (pvz: MappedPVZT) => {
            clearClickedPlacemark()

            const coordinates = [Number(pvz.latitude), Number(pvz.longitude)]
            setMapCenter(coordinates)

            setAddressState({
                address: pvz.full_address,
                latitude: coordinates[0],
                longitude: coordinates[1],
                pvz_name: pvz.name,
                is_pvz: true,
                pvz_id: pvz.id,
                warehouse_id: null,
                is_warehouse: false,
                street: null,
                house: null,
                is_finded: true,
            })

            pvz_state.pvz = { ...pvz }
        }

        const onWarehouseMarkClick = (warehouse: WarehouseT | null) => {
            if (!warehouse) return

            clearClickedPlacemark()
            const coordinates = [Number(warehouse.latitude), Number(warehouse.longitude)]
            setMapCenter(coordinates)

            pvz_state.pvz = null

            setAddressState({
                address: warehouse.full_address,
                latitude: coordinates[0],
                longitude: coordinates[1],
                pvz_name: null,
                is_pvz: false,
                pvz_id: null,
                warehouse_id: warehouse.id,
                is_warehouse: true,
                street: warehouse.street,
                house: warehouse.house,
                is_finded: true,
            })
        }

        const initializePVZ = () => {
            if (!state.distribution_center_id) return

            const finded_pvz = pvz_state.list.find((pvz) => pvz.id === state.distribution_center_id)
            if (!finded_pvz) return

            onPVZMarkClick({ ...finded_pvz })
        }

        const initializeWarehouse = () => {
            if (!state.warehouse_id || !warehouses_state.warehouse) return
            if (state.warehouse_id !== warehouses_state.warehouse.id) return

            onWarehouseMarkClick(warehouses_state.warehouse)
        }

        const initializeCoordinates = () => {
            if (!state.latitude || !state.longitude) {
                return
            }

            if (!state.warehouse_id && !state.distribution_center_id) {
                pvz_state.pvz = null

                const coordinates = [state.latitude, state.longitude]
                setClickedMark(coordinates)
                setMapCenter(coordinates)

                setAddressState({
                    address: state.full_address,
                    latitude: state.latitude,
                    longitude: state.longitude,
                    pvz_name: null,
                    is_pvz: false,
                    pvz_id: null,
                    warehouse_id: null,
                    is_warehouse: false,
                    street: state.street,
                    house: state.house,
                    is_finded: true,
                })
            }
        }

        const setPVZMarks = () => {
            pvz_state.list.forEach((pvz) => {
                const coordinates = [pvz.latitude, pvz.longitude]

                // @ts-ignore
                const placemark = new window.ymaps.Placemark(
                    coordinates,
                    {
                        hintContent: `Пункт выдачи заказов (${pvz.name})`,
                    },
                    {
                        preset: 'islands#bluePostCircleIcon',
                    }
                )

                placemark.events.add('click', () => {
                    onPVZMarkClick(pvz)
                })

                // @ts-ignore
                map_state.instance.geoObjects.add.bind(toRaw(map_state.instance.geoObjects))(
                    placemark
                )
            })
        }

        const setWarehouseMark = () => {
            if (!warehouses_state.warehouse) return
            const coordinates = [
                Number(warehouses_state.warehouse.latitude),
                Number(warehouses_state.warehouse.longitude),
            ]

            // @ts-ignore
            const placemark = new window.ymaps.Placemark(
                coordinates,
                {
                    hintContent: `Склад (${warehouses_state.warehouse.city})`,
                },
                {
                    preset: 'islands#blueHomeCircleIcon',
                }
            )

            placemark.events.add('click', () => {
                onWarehouseMarkClick(warehouses_state.warehouse)
            })

            // @ts-ignore
            map_state.instance.geoObjects.add.bind(toRaw(map_state.instance.geoObjects))(placemark)
        }

        const setClickedMark = (coordinates: number[]) => {
            clearClickedPlacemark()

            // @ts-ignore
            const placemark = new window.ymaps.Placemark(coordinates, null, {
                preset: 'islands#yellowDotIcon',
            })

            // @ts-ignore
            map_state.instance.geoObjects.add.bind(toRaw(map_state.instance.geoObjects))(placemark)
        }

        const getCoordsByAddressYandex = async () => {
            if (!state.city) return

            if (state.city.latitude && state.city.longitude) {
                setMapCenter([state.city.latitude, state.city.longitude])

                if (state.city.coordinates) {
                    // @ts-ignore
                    toRaw(map_state.instance).options.set('restrictMapArea', state.city.coordinates)
                }

                return
            }

            try {
                const mapsRealisation = new MapsRealisation()
                const response = await mapsRealisation.getYandexCoordsByAddress(state.city.name)

                if (!response) {
                    console.error('Не удалось получить координаты города', state.city.name)
                    return
                }

                setMapCenter(response)
            } catch (error) {
                console.error(error)
            }
        }

        const onMapCreated = async (instance: any) => {
            map_state.instance = instance

            if (state.city) {
                await getCoordsByAddressYandex()

                // @ts-ignore
                map_state.instance.geoObjects.removeAll.bind(toRaw(map_state.instance.geoObjects))()

                // await loadPVZ(state.city.id)
                // setPVZMarks()
                // initializePVZ()

                await loadWarehouse(state.city.id)
                setWarehouseMark()

                initializeWarehouse()
                initializeCoordinates()
            }
        }

        const getClickedAdressYandex = async (coordinates: number[]) => {
            try {
                address_state.is_loading = true
                const mapsRealisation = new MapsRealisation()
                const response = await mapsRealisation.getYandexAdressByCoords(coordinates)

                if (!response) return

                address_state.address = response.full_address
                address_state.street = response.street
                address_state.house = response.house

                address_state.is_failed = false
                address_state.is_finded = true
            } catch (error) {
                console.error(error)
                address_state.is_failed = true
            } finally {
                address_state.is_loading = false
            }
        }

        const getClickedAddressGis = async (coordinates: number[]) => {
            try {
                address_state.is_loading = true

                const mapsRealisation = new MapsRealisation()
                const response = await mapsRealisation.geocodeAddressByGisCoords(coordinates)

                if (!response) return
                address_state.address = response.full_address
                address_state.street = response.street

                address_state.is_failed = false
                address_state.is_finded = true
            } catch (error) {
                console.error(error)
                address_state.is_failed = true
            } finally {
                address_state.is_loading = false
            }
        }

        const onMapClicked = (coordinates: number[]) => {
            if (address_state.is_loading) {
                return
            }

            setClickedMark(coordinates)
            setMapCenter(coordinates)

            pvz_state.pvz = null

            setAddressState({
                latitude: coordinates[0],
                longitude: coordinates[1],
                address: null,
                street: null,
                house: null,
                is_warehouse: false,
                warehouse_id: null,
                is_pvz: false,
                pvz_name: null,
                pvz_id: null,
                is_finded: false,
            })

            if (map_state.is_yandex || map_state.is_google) getClickedAdressYandex(coordinates)
            if (map_state.is_gis) getClickedAddressGis(coordinates)
        }

        const onMapSwitched = (map_key: string) => {
            if (map_key === MAP_KEYS.yandex) {
                map_state.is_yandex = true
                map_state.is_gis = false
                map_state.is_google = false
            }

            if (map_key === MAP_KEYS.gis) {
                map_state.is_gis = true
                map_state.is_yandex = false
                map_state.is_google = false
            }

            if (map_key === MAP_KEYS.google) {
                map_state.is_gis = false
                map_state.is_yandex = false
                map_state.is_google = true
            }
        }

        const confirmAddress = () => {
            state.full_address = address_state.address
            state.office = address_state.house
            state.house = address_state.house
            state.street = address_state.street

            state.latitude = address_state.latitude
            state.longitude = address_state.longitude

            state.warehouse_id = address_state.warehouse_id
            state.distribution_center_id = address_state.pvz_id

            map_state.opened = false
            resetAddressState()

            if (state.warehouse_id && !props.isExpress) {
                delivery_type.value = DELIVERY_TYPES[1]
                return
            }

            // if (state.distribution_center_id) {
            //     delivery_type.value = DELIVERY_TYPES[1]
            //     return
            // }

            if (!props.isExpress) {
                delivery_type.value = DELIVERY_TYPES[0]
            }
        }

        const onFindedItemSelect = (result: MapSearchResultT) => {
            setClickedMark(result.points)
            setMapCenter(result.points)

            setAddressState({
                address: result.full_address,
                street: result.street,
                house: result.house,
                latitude: result.points[0],
                longitude: result.points[1],
                is_finded: true,
                is_pvz: false,
                pvz_name: null,
                pvz_id: null,
                warehouse_id: null,
                is_warehouse: false,
            })

            pvz_state.pvz = null
        }

        // Functions
        const onPVZChange = (pvz: PVZT | null) => {
            clearClickedPlacemark()

            if (!pvz) {
                resetAddressState()
                return
            }

            const coordinates = [Number(pvz.latitude), Number(pvz.longitude)]
            setMapCenter([Number(pvz.latitude), Number(pvz.longitude)])

            setAddressState({
                address: pvz.full_address,
                street: null,
                house: null,
                latitude: coordinates[0],
                longitude: coordinates[1],
                is_finded: true,
                is_pvz: true,
                pvz_name: pvz.name,
                pvz_id: pvz.id,
                warehouse_id: null,
                is_warehouse: false,
            })
        }

        const initializeCity = (city_id: number) => {
            if (!city_id) return
            const city = receiverCities.data.find((city: CityT) => city.id === city_id)

            if (!city) return
            state.city = city
        }

        const initializeComponent = async () => {
            const initialValue = getReceiverActionStateRaw()

            if (isUPDATE.value && initialValue) {
                state.id = initialValue.id
                state.title = initialValue.title
                state.full_name = initialValue.full_name
                state.phone = initialValue.phone
                state.additional_phone = initialValue.additional_phone
                state.full_address = initialValue.full_address
                state.latitude = Number(initialValue.latitude)
                state.longitude = Number(initialValue.longitude)
                state.index = initialValue.index
                state.office = initialValue.office
                state.comment = initialValue.comment
                state.house = initialValue.house
                state.street = initialValue.street
                state.warehouse_id = initialValue.warehouse_id
                state.distribution_center_id = initialValue.distribution_center_id

                if (state.warehouse_id && !props.isExpress) {
                    delivery_type.value = DELIVERY_TYPES[1]
                }

                if (!state.distribution_center_id && !state.warehouse_id && !props.isExpress) {
                    delivery_type.value = DELIVERY_TYPES[0]
                }
            }

            await loadReceiverCities(props.isFTL, props.isLTL, props.senderCityId, props.isExpress)

            if (!isUPDATE.value && props.isExpress) {
                state.city = receiverCities.data[0]
            }

            if (initialValue) {
                initializeCity(initialValue.city_id)
                loadWarehouse(initialValue.city_id)
            }

            if (props.isExpress) {
                delivery_type.value = DELIVERY_TYPES[0]
            }
        }

        // Hooks subscriptions
        onMounted(initializeComponent)

        //
        const { unmaskNumbers } = useMaska()

        const onSubmit = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            const payload: NewReceiverCreateState = toRaw({ ...state })
            if (payload.phone) payload.phone = '+' + unmaskNumbers(payload.phone)
            if (payload.additional_phone)
                payload.additional_phone = '+' + unmaskNumbers(payload.additional_phone)

            if (isCREATE.value) {
                context.emit('create', payload)
                return
            }

            if (isUPDATE.value) {
                context.emit('update', payload)
                return
            }
        }

        const isMapCreated = computed(() => Boolean(map_state.instance))

        const isAddressDisabled = computed(() => {
            return (
                !isMapCreated.value ||
                (props.status && props.isLTL && ![201, 203, 225, 205, 206].includes(props.status))
            )
        })

        return {
            isUPDATE,
            isCREATE,
            receiverCities,
            isReceiverActionsLoading,
            // constants
            DELIVERY_TYPES,
            // statements
            delivery_type,
            state,
            address_state,
            map_state,
            warehouses_state,
            pvz_state,
            // validation
            v$,
            // emits
            emitClose,
            // maps functions
            openMap,
            closeMap,
            onMapCreated,
            onMapClicked,
            onMapSwitched,
            confirmAddress,
            onFindedItemSelect,
            // functions
            onPVZChange,
            onCityChange,
            onDeliveryTypeChange,
            //
            onSubmit,
            isMapCreated,
            isAddressDisabled,
        }
    },
})
