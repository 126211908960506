export const useFormData = () => {
    // Работает только для объектов с 1-ступенчатой вложенностью
    // Например, если Object/Array внутри payload
    const getFormData = (payload: Record<string, any>): FormData => {
        const form_data = new FormData()

        Object.entries(payload).forEach(([key, value]) => {
            // пустое значение
            if (!value && value !== 0) {
                form_data.append(key, '')
                return
            }
            // файлы
            else if (Array.isArray(value) && value[0] && value[0] instanceof File) {
                value.forEach((file) => {
                    form_data.append(`${key}[]`, file)
                })
            }
            // объект или массив первого уровня вложенности
            else if (typeof value === 'object') {
                form_data.append(key, JSON.stringify(value))
            }
            // простые значения
            else {
                form_data.append(key, value.toString())
            }
        })

        return form_data
    }

    return { getFormData }
}
