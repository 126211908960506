import { RequestT, ShortPaginationT } from '@/core/types/common.types'
import { OrderMemberPaginatedState } from '@/core/types/Order.types'

import { ShortPaginationBuilder } from './Pagination.builders'
import { RequestStateBuilder } from './RequestState.builder'

export class OrderMemberPaginatedStateBuilder<T> implements OrderMemberPaginatedState<T> {
    public data: T
    public request: RequestT
    public pagination: ShortPaginationT

    constructor(data: T) {
        this.data = data
        this.request = new RequestStateBuilder()
        this.pagination = new ShortPaginationBuilder()
    }
}
