
import { defineComponent, PropType, reactive } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import { useDebounce } from 'vue-composable'
import MapsRealisation from '@/core/realisations/Maps.realisation'
import { OptionT } from '@/core/types/common.types'
import { MapSearchResultT } from '@/core/types/Map.types'

export default defineComponent({
    components: {
        's-loader': SLoader,
    },
    props: {
        city: {
            type: Object as PropType<OptionT<number>>,
            default: null,
        },
        geocodeByYandex: {
            type: Boolean,
            default: true,
        },
        geocodeByGis: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['select'],
    setup(props, { emit }) {
        const searchState = reactive({
            query: null,
            last_query: null,
            results: [] as any[],
            state: {
                is_loading: false,
                is_failed: false,
            },
        })

        const mapsRealisation = new MapsRealisation()

        const search = useDebounce(async () => {
            try {
                searchState.state.is_loading = true
                searchState.results = []
                searchState.last_query = searchState.query

                if (!searchState.query) return

                let response = [] as any[]

                const query = props.city
                    ? `${props.city.name}, ${searchState.query}`
                    : searchState.query

                if (!query) return

                if (props.geocodeByYandex) {
                    let yandexResponse = await mapsRealisation.getListByAddress(query)
                    response = yandexResponse
                }

                if (props.geocodeByGis) {
                    let gisResponse = await mapsRealisation.geocodeAddressListByGis(query)
                    response = gisResponse
                }
                searchState.results = response
                searchState.state.is_failed = false
            } catch (error) {
                console.error(error)
                searchState.state.is_failed = true
            } finally {
                searchState.state.is_loading = false
            }
        }, 500)

        const selectResult = (result: MapSearchResultT) => {
            searchState.query = null
            searchState.last_query = null

            emit('select', result)
            searchState.results = []
        }

        return { searchState, search, selectResult }
    },
})
