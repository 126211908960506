<template>
    <div class="SMap">
        <div class="SMap__header">
            <h2 class="title">Укажите точку на карте</h2>
            <icon-close class="header__close" @click="emitClose" />
        </div>

        <div class="SMap__loading" v-if="state.is_loading">
            <s-loader />
            <p class="loading__text">Загрузка карты...</p>
        </div>

        <div class="SMap__fail" v-if="state.is_fail">
            <icon-refresh class="fail__icon" />
            <p class="fail__text">Ошибка при загрузке карты</p>
        </div>

        <div class="SMap__map" v-else>
            <div class="map__layer">
                <template v-if="!state.is_loading">
                    <div class="map__switcher">
                        <div
                            class="switcher__switch"
                            :class="{ active: state.is_yandex }"
                            @click="switchMap(MAP_KEYS.yandex)"
                        >
                            <icon-yandex class="switch__yandex" />
                        </div>

                        <div
                            class="switcher__switch"
                            :class="{ active: state.is_gis }"
                            @click="switchMap(MAP_KEYS.gis)"
                        >
                            <icon-gis class="switch__gis" />
                        </div>

                        <div
                            class="switcher__switch"
                            :class="{ active: state.is_google }"
                            @click="switchMap(MAP_KEYS.google)"
                        >
                            <icon-google class="switch__google" />
                        </div>
                    </div>

                    <slot name="layer" />
                </template>

                <section
                    v-show="!state.is_loading && !state.is_fail"
                    id="yandex-map"
                    class="map__section"
                />
            </div>

            <slot v-if="!state.is_loading" />
        </div>
    </div>
</template>

<script>
import { defineComponent, onBeforeUnmount, onMounted, reactive, toRaw } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

import iRefresh from '@/assets/icons/Refresh.svg'
import iClose from '@/assets/icons/Close.svg'

import iGis from '@/assets/icons/map/Gis.svg'
import iYandex from '@/assets/icons/map/Yandex.svg'
import iGoogle from '@/assets/icons/map/Google.svg'

const YANDEX_API_KEY = 'f54e428c-22e6-427d-8f09-b2a41f6588ba'
// d5342b0d-3ac4-4899-bd3a-40479d0c3c80
const YANDEX_API_LANG = 'ru_RU'

const DEFAULT_MAP_ZOOM = 15
const DEFAULT_MAP_CENTER = [43.25391893213578, 76.92523545812777]

import { MAP_KEYS } from '@/core/constants/Map.constants'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'icon-close': iClose,
        'icon-refresh': iRefresh,
        'icon-gis': iGis,
        'icon-yandex': iYandex,
        'icon-google': iGoogle,
    },
    emits: ['script-loaded', 'map-created', 'map-switched', 'close', 'click'],
    setup(_, { emit }) {
        const state = reactive({
            is_loading: false,
            is_fail: false,
            is_yandex: true,
            is_gis: false,
            is_google: false,
            mapInstance: null,
        })

        const addGisLayers = () => {
            const gis_layer = function () {
                return new window.ymaps.Layer('http://tile2.maps.2gis.com/tiles?x=%x&y=%y&z=%z', {
                    notFoundTile: ''.concat('//tile%d|2.maps.2gis.com/tiles', '/2/0-0.jpeg'),
                    projection: window.ymaps.projection.sphericalMercator,
                })
            }

            window.ymaps.layer.storage.add('2gis#layer', gis_layer)
            window.ymaps.mapType.storage.add(
                '2gis#map',
                new window.ymaps.MapType('2gis#map', ['2gis#layer'])
            )
        }

        const addGoogleLayer = () => {
            const google_layer = function () {
                return new window.ymaps.Layer('https://mt0.google.com/vt/lyrs=m&x=%x&y=%y&z=%z', {
                    notFoundTile: ''.concat('//tile%d|2.maps.2gis.com/tiles', '/2/0-0.jpeg'),
                    projection: window.ymaps.projection.sphericalMercator,
                })
            }

            window.ymaps.layer.storage.add('google#layer', google_layer)
            window.ymaps.mapType.storage.add(
                'google#map',
                new window.ymaps.MapType('google#map', ['google#layer'])
            )
        }

        const emitClick = (event) => {
            const clicked_coords = event.get('coords')
            emit('click', clicked_coords)
        }

        const createYandexMap = () => {
            if (!isScriptExists()) return

            const map = new window.ymaps.Map('yandex-map', {
                center: DEFAULT_MAP_CENTER,
                zoom: DEFAULT_MAP_ZOOM,
                controls: [],
            })

            map.events.add('click', emitClick)

            state.mapInstance = map
            addGisLayers()
            addGoogleLayer()

            emit('map-created', map)
            state.is_loading = false
        }

        const onScriptLoaded = async () => {
            emit('script-loaded')

            try {
                window.ymaps.ready(() => createYandexMap())
            } catch (error) {
                console.error(error)
                state.is_loading = false
                state.is_fail = true
            }
        }

        const isScriptExists = () => {
            const script = document.head.querySelector('script#ymaps')
            return Boolean(script)
        }

        const loadYandexMap = async () => {
            await new Promise((resolve, reject) => {
                try {
                    // creating yandex map
                    const script = document.createElement('script')

                    script.onerror = (error) => {
                        reject('Map script error', error)
                    }

                    script.id = 'ymaps'
                    script.src = `https://enterprise.api-maps.yandex.ru/2.1?apikey=${YANDEX_API_KEY}&lang=${YANDEX_API_LANG}`
                    script.async = true

                    script.onload = onScriptLoaded

                    document.head.append(script)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        }

        const loadMap = async () => {
            try {
                state.is_loading = true
                await loadYandexMap()
                state.is_fail = false
            } catch (error) {
                console.error(error)
                state.is_loading = false
                state.is_fail = true
            }
        }

        onMounted(loadMap)

        const destroyYandexMap = () => {
            document.head.querySelector('script#ymaps').remove()
            if (window.ymaps) window.ymaps = undefined
        }

        onBeforeUnmount(destroyYandexMap)

        const emitClose = () => {
            emit('close')
        }

        const switchMap = (map_key) => {
            if (map_key === MAP_KEYS.yandex) {
                state.is_yandex = true
                state.is_gis = false
                state.is_google = false
            }

            if (map_key === MAP_KEYS.gis) {
                state.is_gis = true
                state.is_yandex = false
                state.is_google = false
            }

            if (map_key === MAP_KEYS.google) {
                state.is_google = true
                state.is_yandex = false
                state.is_gis = false
            }

            state.mapInstance.setType.bind(toRaw(state.mapInstance))(map_key)
            emit('map-switched', map_key)
        }

        return { MAP_KEYS, state, emitClose, switchMap }
    },
})
</script>

<style lang="scss" scoped src="./index.scss" />
