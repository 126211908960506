
import { defineComponent, reactive, toRaw, watch, onMounted, PropType, computed } from 'vue'

// components
import SInput from '@/common/components/SInput/index.vue'
import STextArea from '@/common/components/STextArea/index.vue'
import SRadioGroup from '@/common/components/SRadioGroup/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'
import FileLoader from '@/common/components/FileLoader/index.vue'
import UploadedFile from '@/common/components/UploadedFile/index.vue'

import iNumber from '@/assets/icons/Number.svg'
import iWeight from '@/assets/icons/Weight.svg'
import iPlaces from '@/assets/icons/Places.svg'
import iLength from '@/assets/icons/Length.svg'
import iWidth from '@/assets/icons/Width.svg'
import iHeight from '@/assets/icons/Height.svg'
import iComment from '@/assets/icons/Comment.svg'
import iDeclaredPrice from '@/assets/icons/DeclaredPrice.svg'
import iRefresh from '@/assets/icons/Refresh.svg'

import {
    SHIPMENT_TYPE_OPTIONS,
    SHIPMENT_TYPE_OPTIONS_ONLY_AUTO,
    PAYMENT_TYPE_OPTIONS,
    PAYMENT_METHOD_OPTIONS_PVZ,
    PAYMENT_METHOD_OPTIONS,
    PAYMENT_METHOD_OPTIONS_ON_PAYER_SELECTED,
} from '@/core/constants/common.constants'
import {
    COMPANY_SPARK_DIRECTION_ID,
    LTL_SHIPMENT_TYPE_MESSAGE,
} from '@/core/constants/LTL.constants'

import {
    LTLOrderInvoiceCargoT,
    LTLOrderInvoiceDeliveryT,
    UploadedFileT,
} from '@/core/types/LTL.types'
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'
import { UsersRealisation } from '@/core/realisations/Users.realisation'

import useVuelidate from '@vuelidate/core'
import { PayerT } from '@/core/types/common.types'
import { decimal, integer, minLength, maxLength, required, requiredIf } from '@vuelidate/validators'
import useNotifications from '@/common/composable/useNotifications'
import { LTLOrderInvoice } from '@/core/classes/LTLOrderInvoice.class'
import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { useFormData } from '@/common/composable/useFormData'
import { AdditionalServiceBilling } from '@/new-services/types/billing'

export default defineComponent({
    components: {
        's-input': SInput,
        's-textarea': STextArea,
        's-radio-group': SRadioGroup,
        's-checkbox': SCheckbox,
        's-button': SButton,
        's-loader': SLoader,
        'icon-number': iNumber,
        'icon-weight': iWeight,
        'icon-places': iPlaces,
        'icon-length': iLength,
        'icon-width': iWidth,
        'icon-height': iHeight,
        'icon-comment': iComment,
        'icon-declared-price': iDeclaredPrice,
        'icon-refresh': iRefresh,
        FileLoader,
        UploadedFile,
    },
    props: {
        opened: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isOnlyAuto: {
            type: Boolean,
            default: false,
        },
        senderTitle: {
            type: String,
            default: null,
        },
        companyId: {
            type: Number,
            default: null,
        },
        isPvz: {
            type: Boolean,
            default: false,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        updatingInvoice: {
            type: [Object, null] as PropType<LTLOrderInvoice | null>,
            default: null,
        },
        additionalServices: {
            type: Array as PropType<AdditionalServiceBilling[]>,
            default: () => [],
        },
    },
    emits: ['add-invoice', 'update-invoice'],
    setup(props, { emit }) {
        const notifications = useNotifications()
        const users = new UsersRealisation()
        const { getFormData } = useFormData()
        const invoicesRealisation = new InvoicesRealisation()

        const state = reactive({
            is_loading: false,
            //
            product_name: null as string | null,
            customerInvoiceNumber: '',
            weight: null as string | null,
            places: null as string | null,
            length: null as string | null,
            width: null as string | null,
            height: null as string | null,
            payer_company_id: null as number | null,
            payers: [] as PayerT[],
            annotation: '',
            isReturnDocuments: false,
            verify: false,
            isInsecure: false,
            is_dangerous: false,
            invoice_files: [] as string[],
            certificate_of_safety_files: [] as string[],
            codPayment: '',
            cargo_name: '',
            declaredPrice: '',
            shipmentType: SHIPMENT_TYPE_OPTIONS[0],
            paymentType: PAYMENT_TYPE_OPTIONS[0],
            paymentMethod: PAYMENT_METHOD_OPTIONS[0],
            hasGrid: false,
            hasPallet: false,
            hasSoftPackage: false,
            //
            is_temperature_regime_active: false,
            temperature_regime_type_id: null as number | null,
        })

        const files_state = reactive({
            is_loading: false,
            //
            invoice_files: [] as File[],
            certificate_of_safety_files: [] as File[],
        })

        const files_backend_state = reactive({
            invoice_files: [] as UploadedFileT[],
            certificate_of_safety_files: [] as UploadedFileT[],
        })

        const selectedAdditionalServicesCount = computed(() => {
            let count = 0

            if (state.hasGrid) count++
            if (state.hasPallet) count++
            if (state.hasSoftPackage) count++

            return count
        })

        const data_state = reactive({
            payment_methods: PAYMENT_METHOD_OPTIONS,
            temperature_regimes: {
                is_loading: false,
                is_failed: false,
                list: [] as { id: number; title: string; value: number }[],
            },
            verify: {
                type: 0 as number,
                changed_by_user: false,
                is_loading: false,
                is_failed: false,
            },
        })

        const customInvoiceNumberPlaceholder = computed(() => {
            if (data_state.verify.type === 2 && state.verify) return 'Номер накладной Kaspi'
            return 'Номер накладной заказчика'
        })

        const LTLCargoPaymentValidation = {
            weight: { required, decimal },
            places: { required, integer },
            length: { required, decimal },
            width: { required, decimal },
            height: { required, decimal },
            product_name: { required },
            cargo_name: { required: requiredIf(() => state.isInsecure), minLength: minLength(3) },
            declaredPrice: { required: requiredIf(() => state.isInsecure), decimal },
            codPayment: { decimal },
            customerInvoiceNumber: {
                required: requiredIf(() => data_state.verify.type === 2 && state.verify),
                maxLength: maxLength(30),
            },
            payer_company_id: {
                required: requiredIf(
                    () => state.paymentType.value !== 1 && state.paymentMethod.value === 3
                ),
            },
            annotation: { required: requiredIf(() => selectedAdditionalServicesCount.value >= 2) },
            temperature_regime_type_id: {
                required: requiredIf(() => state.is_temperature_regime_active),
                decimal,
            },
        }

        const LTLCargoFilesValidation = {
            invoice_files: {
                required: requiredIf(
                    () => state.is_dangerous && !files_backend_state.invoice_files.length
                ),
            },
            certificate_of_safety_files: {
                required: requiredIf(
                    () =>
                        state.is_dangerous &&
                        !files_backend_state.certificate_of_safety_files.length
                ),
            },
        }

        const v$ = useVuelidate(LTLCargoPaymentValidation, state)
        const files$ = useVuelidate(LTLCargoFilesValidation, files_state)

        const loadInvoiceFiles = async () => {
            try {
                state.is_loading = true

                const response = await invoicesRealisation.uploadFiles(
                    getFormData({ type: 17, files: files_state.invoice_files })
                )

                return response.data
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При загрузке файлов произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
            } finally {
                state.is_loading = false
            }
        }

        const loadCertificateFiles = async () => {
            try {
                state.is_loading = true

                const response = await invoicesRealisation.uploadFiles(
                    getFormData({ type: 18, files: files_state.certificate_of_safety_files })
                )

                return response.data
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При загрузке файлов произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
            } finally {
                state.is_loading = false
            }
        }

        const deleteFile = async ({ id, index }: { id: number; index: number }, type: string) => {
            try {
                files_state.is_loading = true
                await invoicesRealisation.deleteFile(id)

                if (type === 'invoice') {
                    files_backend_state.invoice_files.splice(index, 1)
                } else if (type === 'certificate') {
                    files_backend_state.certificate_of_safety_files.splice(index, 1)
                }
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При удалении файла произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
            } finally {
                files_state.is_loading = false
            }
        }

        const prepareIsDangerous = async (cargo: LTLOrderInvoiceCargoT) => {
            cargo.is_dangerous = Number(state.is_dangerous)

            if (
                state.is_dangerous &&
                files_state.invoice_files &&
                files_state.invoice_files.length &&
                files_state.certificate_of_safety_files &&
                files_state.certificate_of_safety_files.length
            ) {
                cargo.invoice_files = await loadInvoiceFiles()
                cargo.certificate_of_safety_files = await loadCertificateFiles()

                if (!cargo.invoice_files && !cargo.certificate_of_safety_files) return

                // for front autofill
                cargo.invoice_files_front = files_state.invoice_files
                cargo.certificate_of_safety_files_front = files_state.certificate_of_safety_files
            }
        }

        const confirmHandler = async () => {
            if (props.loading) return

            v$.value.$touch()
            files$.value.$touch()
            if (v$.value.$invalid || files$.value.$invalid) return

            const delivery: LTLOrderInvoiceDeliveryT = {
                shipmentType: state.shipmentType,
                paymentType: state.paymentType,
                paymentMethod: state.paymentMethod,
                codPayment: Number(state.codPayment),
                declaredPrice: Number(state.declaredPrice),
                cargo_name: state.cargo_name,
            }

            if (state.payer_company_id && isOnPayersSelect()) {
                delivery.payer_company_id = state.payer_company_id
            }

            const width = Number(state.width),
                height = Number(state.height),
                length = Number(state.length)

            if (!width || !height || !length) return
            const volume_weight = (width * height * length) / 1000000

            const cargo: LTLOrderInvoiceCargoT = {
                product_name: state.product_name,
                customerInvoiceNumber: state.customerInvoiceNumber,
                weight: Number(state.weight),
                places: Number(state.places),
                length,
                width,
                height,
                volume: volume_weight >= 0.01 ? Number(volume_weight.toFixed(2)) : 0.01,
                annotation: state.annotation,
                isReturnDocuments: state.isReturnDocuments,
                //
                is_dangerous: 0,
                invoice_files: [],
                certificate_of_safety_files: [],
                //
                invoice_files_front: [],
                certificate_of_safety_files_front: [],
                temperature_regime_type_id: state.temperature_regime_type_id,
            }

            await prepareIsDangerous(cargo)

            let verify = null

            // Если выключено и не трогали то null
            // Если включено и отказались 0
            // Если включено и приняли type

            if (data_state.verify.type > 0) {
                if (state.verify) {
                    verify = data_state.verify.type
                } else {
                    verify = 0
                }
            }

            const cargo_and_delivery = {
                verify,
                cargo,
                delivery,
                services: {
                    hasGrid: state.hasGrid,
                    hasPallet: state.hasPallet,
                    hasSoftPackage: state.hasSoftPackage,
                },
            }

            if (props.isUpdate) {
                emit('update-invoice', toRaw(cargo_and_delivery))
            } else {
                emit('add-invoice', toRaw(cargo_and_delivery))
            }
        }

        const loadVerifyType = async () => {
            try {
                data_state.verify.is_loading = true

                const response = await users.getProfile()
                data_state.verify.type = response.data.company.verify

                if (data_state.verify.type) {
                    state.verify = true
                }

                data_state.verify.is_failed = false
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При получении типа верификации произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
                data_state.verify.is_failed = true
            } finally {
                data_state.verify.is_loading = false
            }
        }

        const loadTemperatureRegimes = async () => {
            try {
                data_state.temperature_regimes.is_loading = true

                const response = await invoicesRealisation.getTemperatureRegimes()
                data_state.temperature_regimes.list = response.data.map((option) => {
                    return {
                        ...option,
                        value: option.id,
                    }
                })

                data_state.temperature_regimes.is_failed = false
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При получении типа верификации произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
                data_state.verify.is_failed = true
            } finally {
                data_state.verify.is_loading = false
            }
        }

        const resetFiles = () => {
            state.invoice_files = []
            state.certificate_of_safety_files = []

            files_state.invoice_files = []
            files_state.certificate_of_safety_files = []
        }

        watch(
            () => props.opened,
            (isOpened) => {
                if (!isOpened) {
                    state.customerInvoiceNumber = ''
                    state.weight = null
                    state.places = null
                    state.length = null
                    state.width = null
                    state.height = null
                    state.annotation = ''
                    state.isReturnDocuments = false
                    state.codPayment = ''
                    state.declaredPrice = ''
                    state.payer_company_id = null
                    state.payers = []
                    state.shipmentType = SHIPMENT_TYPE_OPTIONS[0]
                    state.paymentType = PAYMENT_TYPE_OPTIONS[0]
                    state.paymentMethod = PAYMENT_METHOD_OPTIONS[0]
                    data_state.payment_methods = PAYMENT_METHOD_OPTIONS
                    state.hasGrid = false
                    state.hasPallet = false
                    state.hasSoftPackage = false

                    data_state.verify.is_loading = false
                    data_state.verify.is_failed = false
                    data_state.verify.type = 0
                    data_state.verify.changed_by_user = false

                    state.is_dangerous = false
                    resetFiles()

                    v$.value.$reset()
                    files$.value.$reset()
                } else {
                    if (props.isPvz) {
                        data_state.payment_methods = PAYMENT_METHOD_OPTIONS_PVZ
                    }

                    loadPayers()
                    loadVerifyType()
                }
            }
        )

        const loadPayers = async () => {
            if (!props.companyId) return

            try {
                state.is_loading = true

                const response = await ReceiversRealisation.getPayers(props.companyId, 1)

                const payers = response.data.filter(
                    (option) => option.id !== COMPANY_SPARK_DIRECTION_ID
                )
                state.payers = payers.map((option) => {
                    return { ...option, value: option.id }
                })
            } catch (error) {
                console.error(error)
            } finally {
                state.is_loading = false
            }
        }

        const getValue = (service_name: string, key: keyof AdditionalServiceBilling) => {
            const service = props.additionalServices.find((s) => s.name === service_name)
            if (!service) return '-'
            return service[key]
        }

        const isOnPayersSelect = () => {
            return (
                (state.paymentType.value === 2 || state.paymentType.value === 3) &&
                state.paymentMethod.value === 3
            )
        }

        const onPayerSelect = (payer: PayerT | null) => {
            if (!payer || state.paymentType.value === 2) return
            data_state.payment_methods = PAYMENT_METHOD_OPTIONS_ON_PAYER_SELECTED
            state.paymentMethod = PAYMENT_METHOD_OPTIONS_ON_PAYER_SELECTED[0]
        }

        const onPaymentTypeChange = (type: { id: number; name: string }) => {
            state.payer_company_id = null

            if (type.id === 3) return

            if (props.isPvz) {
                data_state.payment_methods = PAYMENT_METHOD_OPTIONS_PVZ
            } else {
                data_state.payment_methods = PAYMENT_METHOD_OPTIONS
            }
        }

        const onShipmentTypeChange = ({ id }: { id: number }) => {
            if (id === 2) alert(LTL_SHIPMENT_TYPE_MESSAGE)
        }

        const onIsDangerousChange = (is_dangerous: boolean) => {
            if (!is_dangerous) resetFiles()
        }

        const onTemperatureRegimeChange = (is_checked: boolean) => {
            if (!is_checked) state.temperature_regime_type_id = null
        }

        //

        const disabledPaymentTypeOptionsIds = computed(() => {
            if (state.payers.length) return []
            if (state.paymentMethod.value === 2 || state.paymentMethod.value === 4) return []

            return [2, 3]
        })
        const isInvoiceFiles = computed(() => {
            return files_backend_state.invoice_files.length
        })
        const isCertificate = computed(() => {
            return files_backend_state.certificate_of_safety_files.length
        })
        const isTransferPayment = computed(() => state.paymentMethod.value === 3)
        const getPaymentTypeOptions = computed(() => {
            if (state.paymentMethod.value !== 3) {
                return PAYMENT_TYPE_OPTIONS.filter((option) => option.value !== 3)
            }

            return PAYMENT_TYPE_OPTIONS
        })

        //

        onMounted(async () => {
            loadTemperatureRegimes()

            if (props.isUpdate && props.updatingInvoice) {
                const cargo = props.updatingInvoice.cargo

                state.product_name = cargo.product_name
                state.customerInvoiceNumber = cargo.customerInvoiceNumber
                state.weight = cargo.weight.toString()
                state.places = cargo.places.toString()
                state.length = cargo.length.toString()
                state.width = cargo.width.toString()
                state.height = cargo.height.toString()
                state.annotation = cargo.annotation
                state.isReturnDocuments = cargo.isReturnDocuments

                state.is_dangerous = Boolean(cargo.is_dangerous)

                state.is_temperature_regime_active = Boolean(cargo.temperature_regime)
                state.temperature_regime_type_id = cargo.temperature_regime
                    ? cargo.temperature_regime.id
                    : null

                if (state.is_dangerous) {
                    state.invoice_files = cargo.invoice_files as string[]
                    state.certificate_of_safety_files =
                        cargo.certificate_of_safety_files as string[]

                    files_state.invoice_files = cargo.invoice_files_front
                    files_state.certificate_of_safety_files =
                        cargo.certificate_of_safety_files_front

                    files_backend_state.invoice_files = cargo.invoice_files as UploadedFileT[]
                    files_backend_state.certificate_of_safety_files =
                        cargo.certificate_of_safety_files as UploadedFileT[]
                }

                const cod_payment = props.updatingInvoice.delivery.codPayment.toString()
                state.codPayment = cod_payment === '0' ? '' : cod_payment

                if (props.updatingInvoice.delivery.cargo_name) {
                    state.isInsecure = true
                    state.cargo_name = props.updatingInvoice.delivery.cargo_name

                    const declared_price = props.updatingInvoice.delivery.declaredPrice.toString()
                    state.declaredPrice = declared_price === '0' ? '' : declared_price
                }

                state.shipmentType = props.updatingInvoice.delivery.shipmentType
                state.paymentType = props.updatingInvoice.delivery.paymentType
                state.paymentMethod = props.updatingInvoice.delivery.paymentMethod

                state.hasGrid = props.updatingInvoice.additional_services.hasGrid
                state.hasPallet = props.updatingInvoice.additional_services.hasPallet
                state.hasSoftPackage = props.updatingInvoice.additional_services.hasSoftPackage

                if (props.updatingInvoice.verify !== null) {
                    await loadVerifyType()

                    if (props.updatingInvoice.verify === 0) {
                        state.verify = false
                    } else {
                        state.verify = true
                    }
                }

                await loadPayers()
                state.payer_company_id = props.updatingInvoice.delivery.payer_company_id as
                    | number
                    | null
            }
        })

        //

        return {
            state,
            files_state,
            files_backend_state,
            data_state,
            //
            v$,
            files$,
            //
            confirmHandler,
            isOnPayersSelect,
            onPayerSelect,
            onPaymentTypeChange,
            onShipmentTypeChange,
            onIsDangerousChange,
            loadVerifyType,
            deleteFile,
            onTemperatureRegimeChange,
            getValue,
            //
            SHIPMENT_TYPE_OPTIONS,
            SHIPMENT_TYPE_OPTIONS_ONLY_AUTO,
            PAYMENT_TYPE_OPTIONS,
            //
            disabledPaymentTypeOptionsIds,
            customInvoiceNumberPlaceholder,
            isInvoiceFiles,
            isCertificate,
            getPaymentTypeOptions,
            isTransferPayment,
        }
    },
})
