
import { defineComponent, onBeforeMount, reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useTitle } from 'vue-composable'

import SLoader from '@/common/components/SLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SModal from '@/common/components/SModal/index.vue'

import OrderProgress from '@/modules/CreateOrder/components/OrderProgress/index.vue'
import LTLInvoices from '@/modules/CreateOrder/components/LTLInvoices/index.vue'
import LTLReceiversPanel from '@/modules/CreateOrder/components/LTLReceiversPanel/index.vue'
import LTLReceiver from '@/modules/CreateOrder/components/LTLReceiver/index.vue'
import LTLCargoPayment from '@/modules/CreateOrder/components/LTLCargoPayment/index.vue'
import SuccessOrder from '@/modules/CreateOrder/components/SuccessOrder/index.vue'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'

import { LTLOrderForUpdate } from '@/core/types/Responses/Invoices.responses'
import { LTLOrderInvoiceT } from '@/core/types/LTL.types'
import { ReceiverT } from '@/core/types/common.types'
import { LTLOrderInvoiceCargoAndDeliveryT } from '@/core/types/LTL.types'
import { LTLOrderInvoice } from '@/core/classes/LTLOrderInvoice.class'

import {
    SHIPMENT_TYPE_OPTIONS,
    PAYMENT_TYPE_OPTIONS,
    PAYMENT_METHOD_OPTIONS,
    EMPTY_ADDITIONAL_SERVICES,
} from '@/core/constants/common.constants'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-loader': SLoader,
        's-button': SButton,
        's-modal': SModal,
        'order-progress': OrderProgress,
        'ltl-invoices': LTLInvoices,
        'ltl-receivers-panel': LTLReceiversPanel,
        'ltl-receiver': LTLReceiver,
        'ltl-cargo-payment': LTLCargoPayment,
        'success-order': SuccessOrder,
    },
    setup() {
        const route = useRoute()
        const order_id = Number(route.params.order_id)

        useTitle(`Добавление накладных в заказ ${order_id}`)
        const notifications = useNotifications()

        const state = reactive({
            is_loading: false,
            is_failed: false,
            is_loaded: false,
            order: null as LTLOrderForUpdate | null,
            order_invoices: [] as LTLOrderInvoiceT[],
            new_invoices: [] as LTLOrderInvoice[],
            //
            is_complete_loading: false,
            is_completed: false,
        })

        const invoicesRealisation = new InvoicesRealisation()

        const loadOrder = async () => {
            if (!order_id) return

            try {
                state.is_loading = true

                const response = await invoicesRealisation.getLTLOrderForUpdate(order_id)
                state.order = response.data

                state.order_invoices = state.order.receivers.map((receiver) => {
                    const shipmentType = SHIPMENT_TYPE_OPTIONS.find(
                        (option) => option.value === receiver.shipment_type_id
                    )
                    const paymentType = PAYMENT_TYPE_OPTIONS.find(
                        (option) => option.value === receiver.payment_type_id
                    )
                    const paymentMethod = PAYMENT_METHOD_OPTIONS.find(
                        (option) => option.value === receiver.payment_method_id
                    )

                    if (!shipmentType || !paymentType || !paymentMethod) {
                        throw new Error('Cant find shipment_type || payment_type || payment_method')
                    }

                    return {
                        additional_services: receiver.additional_services,
                        delivery: {
                            codPayment: Number(receiver.cod_payment),
                            cargo_name: receiver.cargo_name,
                            declaredPrice: receiver.declared_price,
                            shipmentType,
                            paymentType,
                            paymentMethod,
                        },
                        cargo: {
                            product_name: receiver.product_name,
                            customerInvoiceNumber: receiver.dop_invoice_number,
                            volume: receiver.volume,
                            weight: receiver.weight,
                            places: receiver.places,
                            length: receiver.depth,
                            width: receiver.width,
                            height: receiver.height,
                            annotation: receiver.annotation,
                            isReturnDocuments: Boolean(receiver.should_return_document),
                            // NOT FINISHED бэк не прокидывает инфу
                            is_dangerous: receiver.is_dangerous,
                            invoice_files: [],
                            certificate_of_safety_files: [],
                            invoice_files_front: [],
                            certificate_of_safety_files_front: [],
                            temperature_regime_type_id: receiver.temperature_regime_type_id,
                        },
                        receiver: {
                            id: receiver.id,
                            full_address: receiver.full_address,
                            full_name: receiver.full_name,
                            phone: receiver.phone,
                            additional_phone: receiver.additional_phone,
                            title: receiver.title,
                            city_id: receiver.city_id,
                            //
                            comment: null,
                            house: null,
                            index: null,
                            street: '',
                            office: null,
                            latitude: 0,
                            longitude: 0,
                            warehouse_id: null,
                            distribution_center_id: null,
                        },
                    }
                })

                state.is_loaded = true
                state.is_failed = false
            } catch (error) {
                state.is_loaded = false
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const receivers_state = reactive({
            is_loading: false,
            is_failed: false,
            is_loaded: false,
            is_search: false,
            list: [] as ReceiverT[],
            pagination: {
                current_page: 1,
                total_pages: 1,
            },
            receiver: null as ReceiverT | null,
            selected_ids: [] as number[],
        })

        const receiversRealisation = new ReceiversRealisation()

        const loadReceivers = async () => {
            if (!state.order) return

            receivers_state.is_search = false

            try {
                receivers_state.is_loading = true

                const response = await receiversRealisation.getAllLTL(1, state.order.sender.city_id)
                receivers_state.list = response.data.receivers
                receivers_state.pagination.total_pages = response.data.total_pages

                receivers_state.is_failed = false
                receivers_state.is_loaded = true
            } catch (error) {
                console.error(error)

                receivers_state.is_loaded = false
                receivers_state.is_failed = true
            } finally {
                receivers_state.is_loading = false
            }
        }

        const searchReceiver = async (search_query: string) => {
            if (!state.order) return

            if (!search_query) {
                receivers_state.pagination.current_page = 1
                loadReceivers()
                return
            }

            receivers_state.is_search = true

            try {
                receivers_state.is_loading = true

                const response = await receiversRealisation.searchLTL(
                    search_query,
                    state.order.sender.city_id
                )
                receivers_state.list = response.data.receivers

                receivers_state.is_failed = false
                receivers_state.is_loaded = true
            } catch (error) {
                console.error(error)

                receivers_state.is_loaded = false
                receivers_state.is_failed = true
            } finally {
                receivers_state.is_loading = false
            }
        }

        const loadMoreReceivers = async () => {
            if (!state.order) return
            receivers_state.pagination.current_page++

            try {
                receivers_state.is_loading = true

                const response = await receiversRealisation.getAllLTL(
                    receivers_state.pagination.current_page,
                    state.order.sender.city_id
                )
                receivers_state.list = receivers_state.list.concat(response.data.receivers)
                receivers_state.pagination.total_pages = response.data.total_pages

                receivers_state.is_failed = false
                receivers_state.is_loaded = true
            } catch (error) {
                console.error(error)

                receivers_state.is_loaded = false
                receivers_state.is_failed = true
            } finally {
                receivers_state.is_loading = false
            }
        }

        const deleteReceiver = async (receiver_id: number) => {
            try {
                receiversRealisation.delete(receiver_id)
                const deletingIndex = receivers_state.list.findIndex(
                    (member: ReceiverT) => member.id === receiver_id
                )

                receivers_state.list.splice(deletingIndex, 1)
            } catch (error) {
                console.error(error)
            }
        }

        const preload = async () => {
            await loadOrder()
            await loadReceivers()
        }

        onBeforeMount(preload)

        const selectReceiver = (receiver: ReceiverT) => {
            receivers_state.receiver = { ...receiver }
        }

        const resetReceiver = () => {
            receivers_state.receiver = null
        }

        const addInvoice = (cargo_delivery: LTLOrderInvoiceCargoAndDeliveryT) => {
            if (!receivers_state.receiver || !receivers_state.receiver.id) return

            state.new_invoices.push(
                new LTLOrderInvoice(
                    receivers_state.receiver,
                    cargo_delivery.delivery,
                    cargo_delivery.cargo,
                    { ...EMPTY_ADDITIONAL_SERVICES },
                    cargo_delivery.verify
                )
            )
            receivers_state.selected_ids.push(receivers_state.receiver.id)

            resetReceiver()
        }

        const removeInvoice = (invoice_index: number) => {
            const deleting_invoice = state.new_invoices[invoice_index]
            const selectedIndex = receivers_state.selected_ids.findIndex(
                (id: number) => id === deleting_invoice.receiver.id
            )

            receivers_state.selected_ids.splice(selectedIndex, 1)
            state.new_invoices.splice(invoice_index, 1)

            if (state.new_invoices.length < 1) {
                receivers_state.selected_ids = []
            }
        }

        const modal_state = reactive({
            is_opened: false,
        })

        const complete = async () => {
            try {
                state.is_complete_loading = true

                const receivers = state.new_invoices.map((invoice) => {
                    const receiver = invoice.receiverForCreate

                    if (!receiver) return
                    if (!state.order) return

                    return {
                        ...receiver,
                        order_logistics_info: {
                            ...receiver.order_logistics_info,
                            take_date: state.order.take_date,
                            period_id: state.order.period_id,
                            //
                            is_dangerous: invoice.cargo.is_dangerous,
                            invoice_files: invoice.cargo.invoice_files,
                            certificate_of_safety_files: invoice.cargo.certificate_of_safety_files,
                        },
                    }
                })

                await invoicesRealisation.updateLTLOrder(order_id, { receivers })

                state.is_completed = true
                modal_state.is_opened = true
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка добавления накладных',
                    },
                    error
                )

                state.is_completed = false
            } finally {
                state.is_complete_loading = false
            }
        }

        const senderCityId = computed(() => {
            if (state.order) return state.order.sender.city_id
            return null
        })

        return {
            state,
            loadOrder,
            receivers_state,
            loadMoreReceivers,
            searchReceiver,
            deleteReceiver,
            selectReceiver,
            resetReceiver,
            addInvoice,
            removeInvoice,
            complete,
            modal_state,
            senderCityId,
        }
    },
})
