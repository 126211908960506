import { ShortPaginationT } from '@/core/types/common.types'

export class ShortPaginationBuilder implements ShortPaginationT {
    public current_page: number
    public total_pages: number

    constructor() {
        this.current_page = 1
        this.total_pages = 1
    }
}
