import { http } from '@/core/classes/HTTP.class'
import { AxiosResponse } from 'axios'
import { GisGeocodeResponse, GisSuggestsResponse, YandexGeocodeResponse } from '../types/Map.types'

const YANDEX_API_LANG = 'ru_RU'
// const YANDEX_API_KEY = '3a4d4768-2d49-4152-9df8-7fc0e160aea9'
const YANDEX_GEOCODE_KEY = 'f54e428c-22e6-427d-8f09-b2a41f6588ba'
const YANDEX_GEOCODE_URL = 'https://geocode-maps.yandex.ru/1.x'

const GIS_API_LANG = 'ru_KZ'
const GIS_API_KEY = '6906ddfa-c51e-407c-994e-c0b43d50c188'
const GIS_GEOCODE_KEY = 'rupuxx3048'
const GIS_GEOCODE_URL = 'https://catalog.api.2gis.com/3.0/items/geocode'
const GIS_SUGGEST_URL = 'https://catalog.api.2gis.com/3.0/suggests'

export class MapsRepository {
    /**
     * Геокодирование координат в адрес (Yandex)
     * @param coords Координаты для получения адреса
     * @returns Promise<AxiosResponse<any>>
     */
    geocodeAddressByCoords(coords: number[]): Promise<AxiosResponse<YandexGeocodeResponse>> {
        const params = {
            apikey: YANDEX_GEOCODE_KEY,
            format: 'json',
            lang: YANDEX_API_LANG,
            geocode: `${coords[1]},${coords[0]}`,
        }

        return http.get(`${YANDEX_GEOCODE_URL}/`, { params })
    }
    /**
     * Геокодирование адреса в координаты (Yandex)
     * @param address Адрес для получаения координат
     * @returns Promise<AxiosResponse<any>>
     */
    geocodeCoordsByAddress(address: string): Promise<AxiosResponse<YandexGeocodeResponse>> {
        const params = {
            apikey: YANDEX_GEOCODE_KEY,
            format: 'json',
            lang: YANDEX_API_LANG,
            geocode: address,
        }

        return http.get(`${YANDEX_GEOCODE_URL}/`, { params })
    }

    geocodeAddressList(address: string): Promise<AxiosResponse<YandexGeocodeResponse>> {
        const params = {
            apikey: YANDEX_GEOCODE_KEY,
            format: 'json',
            lang: YANDEX_API_LANG,
            geocode: address,
        }

        return http.get(YANDEX_GEOCODE_URL, { params })
    }

    geocodeAddressListByGis(address: string): Promise<AxiosResponse<GisSuggestsResponse>> {
        const params = {
            q: address,
            locale: GIS_API_LANG,
            fields: 'items.point,items.adm_div',
            key: GIS_GEOCODE_KEY,
        }

        return http.get(GIS_SUGGEST_URL, { params })
    }

    geocodeAddressByGisCoords(coords: number[]): Promise<AxiosResponse<GisGeocodeResponse>> {
        const params = {
            lon: coords[1],
            lat: coords[0],
            fields: 'items.full_address_name',
            locale: GIS_API_LANG,
            key: GIS_GEOCODE_KEY,
        }

        return http.get(GIS_GEOCODE_URL, { params })
    }
}
