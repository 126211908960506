
import { defineComponent, PropType } from 'vue'

// import iEdit from '@/assets/icons/Edit.svg'
import { LTLOrderInvoiceCargoT } from '@/core/types/LTL.types'
import { PLACES_TOKENS } from '@/core/constants/common.constants'

export default defineComponent({
    props: {
        cargo: {
            type: Object as PropType<LTLOrderInvoiceCargoT>,
            default: null,
        },
    },
    setup() {
        return { PLACES_TOKENS }
    },
})
