import CitiesI from '../interfaces/Cities.interface'
import citiesService from '../services/Cities.service'
import { CityT } from '../types/common.types'

export class CitiesRealisation implements CitiesI {
    async getAllCities(): Promise<CityT[]> {
        const allCitiesResponse = await citiesService.getAllCities()
        return allCitiesResponse
    }

    async getCitiesByCityId(city_id: number): Promise<CityT[]> {
        const citiesByCityId = await citiesService.getCitiesByCityId(city_id)
        return citiesByCityId
    }
}
