
import { defineComponent, PropType, reactive, computed } from 'vue'

import OrderPoint from '@/modules/CreateOrder/components/OrderPoint/index.vue'
import CompletedCargo from '@/modules/CreateOrder/components/Completed/CompletedCargo/index.vue'
import CompletedDelivery from '@/modules/CreateOrder/components/Completed/CompletedDelivery/index.vue'
import CompletedServices from '@/modules/CreateOrder/components/Completed/CompletedServices/index.vue'
import NewLtlReceiverAdditionalServices from '@/modules/CreateOrder/components/NewLtlReceiverAdditionalServices/index.vue'

import iSettingsAdd from '@/assets/icons/SettingsAdd.svg'
import iTrash from '@/assets/icons/Trash.svg'
import iEdit from '@/assets/icons/Edit.svg'

import { LTLOrderInvoiceT } from '@/core/types/LTL.types'
import { AdditionalServicesT } from '@/core/types/common.types'
import { AdditionalServiceBilling } from '@/new-services/types/billing'

export default defineComponent({
    components: {
        'order-point': OrderPoint,
        'completed-cargo': CompletedCargo,
        'completed-delivery': CompletedDelivery,
        'completed-services': CompletedServices,
        'icon-settings-add': iSettingsAdd,
        'icon-trash': iTrash,
        'icon-edit': iEdit,
        'new-ltl-receiver-additional-services': NewLtlReceiverAdditionalServices,
    },
    props: {
        isFTL: {
            type: Boolean,
            default: false,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
        invoice: {
            type: Object as PropType<LTLOrderInvoiceT>,
            default: null,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
        additionalServices: {
            type: Array as PropType<AdditionalServiceBilling[]>,
            default: () => [],
        },
        isBosch: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'add-additional-services',
        'reset-additional-services',
        'delete-invoice',
        'start-update',
    ],
    setup(props, { emit }) {
        const state = reactive({
            isAdditionalServicesOpened: true,
        })

        const emitAddAdditionalServices = (additional_services: AdditionalServicesT) => {
            emit('add-additional-services', additional_services)
            state.isAdditionalServicesOpened = false
        }

        const emitResetAdditionalServices = () => {
            emit('reset-additional-services')
        }

        const openAdditionalServices = () => {
            state.isAdditionalServicesOpened = true
        }

        const closeAdditionalServices = () => {
            state.isAdditionalServicesOpened = false
        }

        const emitDeleteInvoice = () => {
            emit('delete-invoice')
        }

        const emitStartUpdate = () => {
            emit('start-update')
        }

        const isCanShowAdditionalServices = computed(() => {
            if (!props.invoice.additional_services) return false

            if (
                props.invoice.additional_services.hasCar ||
                props.invoice.additional_services.hasSoftPackage ||
                props.invoice.additional_services.hasManipulator ||
                props.invoice.additional_services.hasCrane ||
                props.invoice.additional_services.hasHydraulicTrolley ||
                props.invoice.additional_services.hasGrid ||
                props.invoice.additional_services.hasLoader ||
                props.invoice.additional_services.hasPallet
            ) {
                return true
            }

            return false
        })

        return {
            state,
            emitAddAdditionalServices,
            emitResetAdditionalServices,
            emitDeleteInvoice,
            openAdditionalServices,
            closeAdditionalServices,
            isCanShowAdditionalServices,
            emitStartUpdate,
        }
    },
})
