
import { defineComponent } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

export default defineComponent({
    components: {
        's-loader': SLoader,
    },
})
