
import { defineComponent, PropType } from 'vue'

// types
import { UploadedFileT } from '@/core/types/LTL.types'

// components
import SIconButton from '@/common/components/SIconButton/index.vue'

// icons
import iTrash from '@/assets/icons/Trash.svg'
import iDownload from '@/assets/icons/Download.svg'

export default defineComponent({
    components: {
        SIconButton,
        iTrash,
        iDownload,
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        files: {
            type: Array as PropType<UploadedFileT[]>,
            default: () => [],
        },
        title: {
            type: String,
            default: 'Title',
        },
        noDelete: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['delete'],
    setup(_, { emit }) {
        const deleteFile = (id: number, index: number) => {
            emit('delete', { id, index })
        }

        return {
            deleteFile,
        }
    },
})
