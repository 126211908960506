import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'
import { WarehousesGetByCityIdResponseT } from '../types/Responses/Warehouses.responses'

enum WarehousesRepositoryRoutesEnum {
    'GET_BY_CITY_ID' = '/warehouses/info',
}

export class WarehousesRepository {
    getByCityId(city_id: number): Promise<AxiosResponse<WarehousesGetByCityIdResponseT>> {
        return http.get(`${WarehousesRepositoryRoutesEnum.GET_BY_CITY_ID}/${city_id}`)
    }
}
