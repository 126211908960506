<template>
    <div
        :class="['SIconButton', { SIconButton__loading: loading }]"
        v-tooltip="{ text: content, theme: { direction: 'bottom' } }"
    >
        <template v-if="loading">
            <s-loader />
        </template>
        <template v-else>
            <slot />
        </template>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

export default defineComponent({
    components: {
        's-loader': SLoader,
    },
    props: {
        content: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
})
</script>

<style lang="scss" src="./index.scss" />
