
import { defineAsyncComponent, defineComponent, PropType, reactive, ref, unref } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import FileLoader from '@/common/components/FileLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import PointInPanel from '@/modules/CreateOrder/components/PointInPanel/index.vue'
import PanelsLoader from '@/modules/CreateOrder/components/PanelsLoader/index.vue'

import SModal from '@/common/components/SModal/index.vue'

import NewReceiverCreate from '@/modules/CreateOrder/components/NewReceiverCreate/index.vue'

import iSearch from '@/assets/icons/Search.svg'
import iBigPlus from '@/assets/icons/BigPlus.svg'
import iDownload from '@/assets/icons/Download.svg'

import { ReceiverT } from '@/core/types/common.types'

import {
    isReceiverActionsOpened,
    useMembersActions,
} from '@/modules/CreateOrder/composable/useMembersActions'

import { useDebounce } from 'vue-composable'
import { ReceiverCreatePayload } from '@/core/types/Order.types'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-input': SInput,
        'file-loader': FileLoader,
        's-button': SButton,
        'point-in-panel': PointInPanel,
        'panels-loader': PanelsLoader,
        's-modal': SModal,
        // 'receiver-create': ReceiverCreate,
        'receiver-create': NewReceiverCreate,
        'icon-search': iSearch,
        'icon-big-plus': iBigPlus,
        'icon-download': iDownload,
    },
    props: {
        selectedReceiversIds: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        isFTL: {
            type: Boolean,
            default: false,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        excelLoading: {
            type: Boolean,
            default: false,
        },
        opened: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        receivers: {
            type: Array as PropType<ReceiverT[]>,
            default: () => [],
        },
        current_page: {
            type: Number,
            default: 1,
        },
        total_pages: {
            type: Number,
            default: 1,
        },
        isSearch: {
            type: Boolean,
            default: false,
        },
        disableExcel: {
            type: Boolean,
            default: false,
        },
        senderCityId: {
            type: Number,
            default: null,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['refresh', 'loadmore', 'select', 'search', 'excel', 'delete'],
    setup(_, { emit }) {
        const notifications = useNotifications()

        const search_query = ref(null)

        const emitRefresh = () => {
            emit('refresh')
        }

        const emitLoadMore = () => {
            emit('loadmore')
        }

        const {
            closeReceiverActions,
            startCreateReceiver,
            startEditReceiver,
            createReceiver,
            updateReceiverLTL,
        } = useMembersActions()

        const emitSelect = (member: ReceiverT) => {
            if (!member.full_address || !member.latitude || !member.longitude) {
                startEditReceiver(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите адрес',
                })
                return
            }

            if (!member.phone) {
                startEditReceiver(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите номер телефона',
                })
                return
            }

            emit('select', member)
        }

        const emitSearch = useDebounce(() => {
            emit('search', unref(search_query))
        }, 1000)

        const createReceiverHandler = async (receiver_payload: ReceiverCreatePayload) => {
            await createReceiver(receiver_payload)
            closeReceiverActions()
        }

        const updateReceiverHandler = async (receiver_payload: ReceiverCreatePayload) => {
            if (!receiver_payload.id) return

            try {
                await updateReceiverLTL(receiver_payload.id, receiver_payload)
                closeReceiverActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Пожалуйста, укажите адрес',
                    },
                    error
                )
            }
        }

        const emitCreateByExcel = (files: File[]) => {
            if (!files.length) return
            emit('excel', files[0])
        }

        const emitDelete = (id: number) => {
            emit('delete', id)
        }

        return {
            isReceiverActionsOpened,
            closeReceiverActions,
            startCreateReceiver,
            startEditReceiver,
            emitRefresh,
            emitLoadMore,
            emitSelect,
            search_query,
            emitSearch,
            createReceiverHandler,
            updateReceiverHandler,
            emitCreateByExcel,
            emitDelete,
        }
    },
})
