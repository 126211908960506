import { AxiosResponse } from 'axios'

import { http } from '@/core/classes/HTTP.class'
import { CitiesByCityIdResponse, CitiesResponse } from '../types/Responses/Cities.responses'
import { ServerResponse } from '../types/Responses/common.responses'

enum CitiesRepositoryRoutesEnum {
    'GET_ALL' = '/v2/cities',
    'GET_BY_ID' = '/direction/get_where_cities',
}

export class CitiesRepository {
    /**
     * Получение полного списка городов
     * @returns Promise<AxiosResponse<ServerResponse<CitiesResponse>>>
     */
    getAllCities(): Promise<AxiosResponse<ServerResponse<CitiesResponse>>> {
        return http.get(CitiesRepositoryRoutesEnum.GET_ALL, { params: { limit: 5000 } })
    }

    /**
     * Получение списка городов по айди города
     * @param city_id id города
     */
    getCitiesByCityId(
        city_id: number
    ): Promise<AxiosResponse<ServerResponse<CitiesByCityIdResponse>>> {
        return http.get(`${CitiesRepositoryRoutesEnum.GET_BY_ID}/${city_id}`)
    }
}
