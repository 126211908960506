import { WarehousesI } from '../interfaces/Warehouses.interface'
import warehousesService from '../services/Warehouses.service'
import { WarehousesGetByCityIdResponseT } from '../types/Responses/Warehouses.responses'

export class WarehousesRealisation implements WarehousesI {
    async getByCityId(city_id: number): Promise<WarehousesGetByCityIdResponseT> {
        const response = await warehousesService.getByCityId(city_id)
        return response
    }
}
