import { DeliveryRepository } from '../repositories/Delivery.repository'
import { DeliveryOrderPayloadT, DeliveryPointT, NewOrderPayload } from '../types/Delivery.types'
import { ExpressQueryFilters } from '../types/History.types'
import {
    DeliveryGetCitiesResponseT,
    DeliveryGetOrderResponseT,
    DeliveryGetOrdersResponseT,
    DeliveryGetTariffsResponseT,
    DeliveryGetTasksResponseT,
    DeliveryRefreshStatusResponseT,
    DeliveryTrackResponseT,
} from '../types/Responses/Delivery.responses'

const deliveryRepository = new DeliveryRepository()

export class DeliveryService {
    async getCities(): Promise<DeliveryGetCitiesResponseT> {
        const response = await deliveryRepository.getCities()
        return response.data
    }

    async getTariffs(coordinates: DeliveryPointT[]): Promise<DeliveryGetTariffsResponseT> {
        const response = await deliveryRepository.getTariffs(coordinates)
        return response.data
    }

    async createExpressOrder(payload: NewOrderPayload): Promise<void> {
        const response = await deliveryRepository.createExpressOrder(payload)
        return response.data
    }

    async getExpressOrders(params: ExpressQueryFilters): Promise<DeliveryGetOrdersResponseT> {
        const response = await deliveryRepository.getExpressOrders(params)
        return response.data
    }

    async cancelOrder(id: number): Promise<void> {
        const response = await deliveryRepository.cancelOrder(id)
        return response.data
    }

    async getExpressOrder(order_id: number): Promise<DeliveryGetOrderResponseT> {
        const response = await deliveryRepository.getExpressOrder(order_id)
        return response.data
    }

    async refreshStatus(order_id: number): Promise<DeliveryRefreshStatusResponseT> {
        const response = await deliveryRepository.refreshStatus(order_id)
        return response.data
    }

    async getTasks(page: number): Promise<DeliveryGetTasksResponseT> {
        const response = await deliveryRepository.getTasks(page)
        return response.data
    }

    async getTrackInformation(order_id: number): Promise<DeliveryTrackResponseT> {
        const response = await deliveryRepository.getTrackInformation(order_id)
        return response.data
    }
}
