
import { defineComponent, PropType } from 'vue'

import iEdit from '@/assets/icons/Edit.svg'
import iCalendar from '@/assets/icons/Calendar.svg'
import iClock from '@/assets/icons/Clock.svg'

import { OptionT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        'icon-edit': iEdit,
        'icon-calendar': iCalendar,
        'icon-clock': iClock,
    },
    props: {
        sendDate: {
            type: String,
            default: null,
        },
        sendTime: {
            type: String,
            default: null,
        },
        sendPeriod: {
            type: [String, Object] as PropType<OptionT<number> | string>,
            default: null,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reset'],
    setup(_, { emit }) {
        const emitReset = () => {
            emit('reset')
        }

        return { emitReset }
    },
})
