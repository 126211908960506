
import { defineComponent, PropType } from 'vue'

import iEdit from '@/assets/icons/Edit.svg'

import { FTLCarT } from '@/core/types/FTL.types'

export default defineComponent({
    components: {
        'icon-edit': iEdit,
    },
    props: {
        car: {
            type: Object as PropType<FTLCarT>,
            default: null,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reset'],
    setup(_, { emit }) {
        const emitReset = () => {
            emit('reset')
        }

        return { emitReset }
    },
})
