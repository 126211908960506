import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import {
    DeliveryGetCitiesResponseT,
    DeliveryGetOrderResponseT,
    DeliveryGetOrdersResponseT,
    DeliveryGetTariffsResponseT,
    DeliveryGetTasksResponseT,
    DeliveryRefreshStatusResponseT,
    DeliveryTrackResponseT,
} from '../types/Responses/Delivery.responses'

import { DeliveryOrderPayloadT, DeliveryPointT, NewOrderPayload } from '../types/Delivery.types'
import { ExpressQueryFilters } from '../types/History.types'

const BASE_URL = `${process.env.VUE_APP_GATEWAY_URL!}/delivery/api`

export class DeliveryRepository {
    getCities(): Promise<AxiosResponse<DeliveryGetCitiesResponseT>> {
        const params = {
            limit: 999,
        }

        return http.get(`${BASE_URL}/city`, { params })
    }

    getTariffs(coordinates: DeliveryPointT[]): Promise<AxiosResponse<DeliveryGetTariffsResponseT>> {
        return http.post(`${BASE_URL}/tariff`, { coordinates })
    }

    createExpressOrder(payload: NewOrderPayload): Promise<AxiosResponse<void>> {
        return http.post(`${BASE_URL}/order`, payload)
    }

    getExpressOrders(
        params: ExpressQueryFilters
    ): Promise<AxiosResponse<DeliveryGetOrdersResponseT>> {
        return http.get(`${BASE_URL}/order`, { params })
    }

    cancelOrder(id: number): Promise<AxiosResponse<void>> {
        return http.post(`${BASE_URL}/order/${id}/cancel`, { id })
    }

    getExpressOrder(order_id: number): Promise<AxiosResponse<DeliveryGetOrderResponseT>> {
        return http.get(`${BASE_URL}/order/${order_id}`)
    }

    refreshStatus(order_id: number): Promise<AxiosResponse<DeliveryRefreshStatusResponseT>> {
        return http.put(`${BASE_URL}/order/status/${order_id}`, {})
    }

    getTasks(page: number): Promise<AxiosResponse<DeliveryGetTasksResponseT>> {
        const params = {
            page,
            limit: 999,
        }

        return http.get(`${BASE_URL}/task`, { params })
    }

    getTrackInformation(order_id: number): Promise<AxiosResponse<DeliveryTrackResponseT>> {
        return http.get(`${BASE_URL}/track/${order_id}`)
    }
}
