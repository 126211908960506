import { FTLCarT, FTLLoadCapacityT } from '@/core/types/FTL.types'
import { OptionT } from '../types/common.types'

export class FTLCar implements FTLCarT {
    liftingCapacity: FTLLoadCapacityT
    cubicCapacity: number
    loadingType: OptionT<string>
    temperatureRegim: string
    customerInvoiceNumber: string
    annotation: string
    declaredPrice: number
    isOversized: boolean
    paymentMethod: OptionT<number>

    constructor(ftl_car: FTLCarT) {
        this.liftingCapacity = ftl_car.liftingCapacity
        this.cubicCapacity = ftl_car.cubicCapacity
        this.loadingType = ftl_car.loadingType
        this.temperatureRegim = ftl_car.temperatureRegim
        this.customerInvoiceNumber = ftl_car.customerInvoiceNumber
        this.annotation = ftl_car.annotation
        this.declaredPrice = ftl_car.declaredPrice
        this.isOversized = ftl_car.isOversized
        this.paymentMethod = ftl_car.paymentMethod
    }
}
