
import { defineComponent, PropType } from 'vue'

import LTLInvoice from '@/modules/CreateOrder/components/LTLInvoice/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import { LTLOrderInvoicesT } from '@/core/types/LTL.types'
import { AdditionalServicesT } from '@/core/types/common.types'
import { useRouter } from 'vue-router'
import { OFFER_LINK } from '@/core/constants/common.constants'
import { AdditionalServiceBilling } from '@/new-services/types/billing'

export default defineComponent({
    components: {
        'ltl-invoice': LTLInvoice,
        's-button': SButton,
    },
    props: {
        isFTL: {
            type: Boolean,
            default: false,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
        invoices: {
            type: Array as PropType<LTLOrderInvoicesT>,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
        withoutHeaderAndFooter: {
            type: Boolean,
            default: false,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Готовые грузы',
        },
        orderId: {
            type: String,
            default: null,
        },
        additionalServices: {
            type: Array as PropType<AdditionalServiceBilling[]>,
            default: () => [],
        },
        isBosch: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'delete',
        'create',
        'start-add',
        'reset',
        'add-invoice-additional-services',
        'reset-invoice-additional-services',
        'update',
    ],
    setup(props, { emit }) {
        const emitDelete = (index: number) => {
            emit('delete', index)
        }

        const emitCreate = () => {
            emit('create')
        }

        const emitStartAdd = () => {
            emit('start-add')
        }

        const router = useRouter()

        const goInInvoices = () => {
            router.push('/history')
            emit('reset')
        }

        const goInOrder = () => {
            router.push(`/order/ltl/${props.orderId}`)
            emit('reset')
        }

        const emitReset = () => {
            emit('reset')
        }

        const emitAddInvoiceAdditionalServices = (
            index: number,
            additional_services: AdditionalServicesT
        ) => {
            emit('add-invoice-additional-services', {
                index,
                additional_services,
            })
        }

        const emitResetInvoiceAdditionalServices = (index: number) => {
            emit('reset-invoice-additional-services', index)
        }

        const emitUpdate = (index: number) => {
            emit('update', index)
        }

        return {
            emitDelete,
            emitCreate,
            emitStartAdd,
            emitAddInvoiceAdditionalServices,
            emitResetInvoiceAdditionalServices,
            goInInvoices,
            goInOrder,
            emitReset,
            OFFER_LINK,
            emitUpdate,
        }
    },
})
