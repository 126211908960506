import moment from 'moment'

import { OrderTypeEnum } from '@/core/enums/Order.enums'
import { OrderMemberPaginatedState } from '../types/Order.types'

import { ReceiversRealisation } from '../realisations/Receivers.realisation'
import { SendersRealisation } from '../realisations/Senders.realisation'

import { AdditionalServicesT, MemberT, SenderT } from '@/core/types/common.types'
import { ReceiverT } from '@/core/types/common.types'
import { EMPTY_ADDITIONAL_SERVICES } from '../constants/common.constants'

export class Order {
    private _type: OrderTypeEnum

    private _sendersRealisation: SendersRealisation
    private _senders: OrderMemberPaginatedState<MemberT[]>
    private _sender: SenderT | null
    private _isSenderSearch: boolean

    private _send_date: string | null

    private _receiversRealisation: ReceiversRealisation
    private _receivers: OrderMemberPaginatedState<any>
    public receiver: ReceiverT | null
    private _isReceiversSearch: boolean

    private _additional_services: AdditionalServicesT
    private _isAdditionalServicesOpened: boolean
    private _isAdditionalServicesSetted: boolean

    constructor(
        type: OrderTypeEnum,
        senders: OrderMemberPaginatedState<any>,
        receivers: OrderMemberPaginatedState<any>
    ) {
        this._type = type

        this._senders = senders
        this._sendersRealisation = new SendersRealisation()
        this._sender = null
        this._isSenderSearch = false

        this._send_date = null

        this._receivers = receivers
        this._receiversRealisation = new ReceiversRealisation()
        this._isReceiversSearch = false
        this.receiver = null

        this._additional_services = { ...EMPTY_ADDITIONAL_SERVICES }
        this._isAdditionalServicesOpened = false
        this._isAdditionalServicesSetted = false
    }

    // methods
    async deleteSender(sender_id: number): Promise<void> {
        try {
            const sendersRealisation = new SendersRealisation()
            sendersRealisation.delete(sender_id)
            const deletingIndex = this._senders.data.findIndex(
                (member: MemberT) => member.id === sender_id
            )
            this._senders.data.splice(deletingIndex, 1)
        } catch (error) {
            console.error(error)
        }
    }

    async deleteReceiver(receiver_id: number): Promise<void> {
        try {
            const receiversRealisation = new ReceiversRealisation()
            receiversRealisation.delete(receiver_id)
            const deletingIndex = this._receivers.data.findIndex(
                (member: MemberT) => member.id === receiver_id
            )
            this._receivers.data.splice(deletingIndex, 1)
        } catch (error) {
            console.error(error)
        }
    }

    async loadSenders(): Promise<void> {
        this._isSenderSearch = false

        try {
            this._senders.request.is_loading = true

            const sendersResponse = await this._sendersRealisation.getAll(
                this._senders.pagination.current_page
            )

            this._senders.data = sendersResponse.data.senders
            this._senders.pagination.total_pages = sendersResponse.data.total_pages

            this._senders.request.is_failed = false
            this._senders.request.is_success = true
        } catch (error) {
            console.error(error)
            this._senders.request.is_success = false
            this._senders.request.is_failed = true
        } finally {
            this._senders.request.is_loading = false
            this._senders.request.is_done = true
        }
    }

    async searchSenders(search_query: string): Promise<void> {
        if (!search_query) {
            this._senders.pagination.current_page = 1
            this.loadSenders()
            return
        }

        try {
            this._isSenderSearch = true
            this._senders.request.is_loading = true

            const sendersResponse = await this._sendersRealisation.search(search_query)
            this._senders.data = sendersResponse.data
        } catch (error) {
            console.error(error)
            this._senders.request.is_success = false
            this._senders.request.is_failed = true
        } finally {
            this._senders.request.is_loading = false
            this._senders.request.is_done = true
        }
    }

    async loadMoreSenders(): Promise<void> {
        try {
            this._senders.request.is_loading = true
            this._senders.pagination.current_page++

            const sendersResponse = await this._sendersRealisation.getAll(
                this._senders.pagination.current_page
            )

            this._senders.data = this._senders.data.concat(sendersResponse.data.senders)
            this._senders.pagination.total_pages = sendersResponse.data.total_pages

            this._senders.request.is_failed = false
            this._senders.request.is_success = true
        } catch (error) {
            console.error(error)
            this._senders.request.is_success = false
            this._senders.request.is_failed = true
        } finally {
            this._senders.request.is_loading = false
            this._senders.request.is_done = true
        }
    }

    selectSender(sender: SenderT): void {
        this._sender = sender
    }

    resetSender(): void {
        this._sender = null
    }

    setSendDate(date: Date): void {
        if (this._type === OrderTypeEnum.FTL) {
            this._send_date = moment(date).format('DD-MM-YYYY')
        }

        if (this._type === OrderTypeEnum.LTL) {
            this._send_date = moment(date).format('YYYY-MM-DD')
        }
    }

    resetSendDate(): void {
        this._send_date = null
    }

    async loadReceivers(): Promise<void> {
        this._isReceiversSearch = false

        try {
            this._receivers.request.is_loading = true
            let receiversResponse = null

            if (this._type === OrderTypeEnum.LTL) {
                if (!this._sender) return
                receiversResponse = await this._receiversRealisation.getAllLTL(
                    this._receivers.pagination.current_page,
                    this._sender.city_id
                )
            }

            if (this._type === OrderTypeEnum.FTL) {
                receiversResponse = await this._receiversRealisation.getAllFTL(
                    this._receivers.pagination.current_page
                )
            }

            if (!receiversResponse) return

            this._receivers.data = receiversResponse.data.receivers
            this._receivers.pagination.total_pages = receiversResponse.data.total_pages

            this._receivers.request.is_failed = false
            this._receivers.request.is_success = true
        } catch (error) {
            console.error(error)
            this._receivers.request.is_success = false
            this._receivers.request.is_failed = true
        } finally {
            this._receivers.request.is_loading = false
            this._receivers.request.is_done = true
        }
    }

    async searchReceivers(search_query: string): Promise<void> {
        if (!search_query) {
            this._receivers.pagination.current_page = 1
            this.loadReceivers()
            return
        }

        try {
            this._isReceiversSearch = true
            this._receivers.request.is_loading = true
            let receiversResponse = null

            if (this._type === OrderTypeEnum.LTL) {
                if (!this._sender) return

                receiversResponse = await this._receiversRealisation.searchLTL(
                    search_query,
                    this._sender.city_id
                )
            }

            if (this._type === OrderTypeEnum.FTL) {
                receiversResponse = await this._receiversRealisation.search(search_query)
            }

            if (!receiversResponse) return
            this._receivers.data = receiversResponse.data.receivers
        } catch (error) {
            console.error(error)
            this._receivers.request.is_success = false
            this._receivers.request.is_failed = true
        } finally {
            this._receivers.request.is_loading = false
            this._receivers.request.is_done = true
        }
    }

    async loadMoreReceivers(): Promise<void> {
        try {
            this._receivers.request.is_loading = true
            this._receivers.pagination.current_page++

            let receiversResponse = null

            if (this._type === OrderTypeEnum.LTL) {
                if (!this._sender) return
                receiversResponse = await this._receiversRealisation.getAllLTL(
                    this._receivers.pagination.current_page,
                    this._sender.city_id
                )
            }

            if (this._type === OrderTypeEnum.FTL) {
                receiversResponse = await this._receiversRealisation.getAllFTL(
                    this._receivers.pagination.current_page
                )
            }

            if (!receiversResponse) return

            this._receivers.data = this._receivers.data.concat(receiversResponse.data.receivers)
            this._receivers.pagination.total_pages = receiversResponse.data.total_pages

            this._receivers.request.is_failed = false
            this._receivers.request.is_success = true
        } catch (error) {
            console.error(error)
            this._receivers.request.is_success = false
            this._receivers.request.is_failed = true
        } finally {
            this._receivers.request.is_loading = false
            this._receivers.request.is_done = true
        }
    }

    resetOrder(): void {
        this._sender = null
        this.receiver = null
        this.resetAdditionalServices()
    }

    openAdditionalServices(): void {
        this._isAdditionalServicesOpened = true
    }

    setAdditionalServices(additional_services: AdditionalServicesT): void {
        this._additional_services = additional_services
        this._isAdditionalServicesOpened = false
        this._isAdditionalServicesSetted = true
    }

    resetAdditionalServices(): void {
        this._additional_services = { ...EMPTY_ADDITIONAL_SERVICES }
        this._isAdditionalServicesSetted = false
    }

    closeAdditionalServices(): void {
        this._isAdditionalServicesOpened = false
    }

    // getters
    get type(): OrderTypeEnum {
        return this._type
    }

    get senders(): OrderMemberPaginatedState<any> {
        return this._senders
    }

    get sender(): SenderT | null {
        return this._sender
    }

    get isSenderSearch(): boolean {
        return this._isSenderSearch
    }

    get send_date(): string | null {
        return this._send_date
    }

    get receivers(): OrderMemberPaginatedState<any> {
        return this._receivers
    }

    get isReceiversSearch(): boolean {
        return this._isReceiversSearch
    }

    get additional_services(): AdditionalServicesT {
        return this._additional_services
    }

    get isAdditionalServicesOpened(): boolean {
        return this._isAdditionalServicesOpened
    }

    get isAdditionalServicesSetted(): boolean {
        return this._isAdditionalServicesSetted
    }
}
