import { Order } from './Order.class'
import { OrderTypeEnum } from '../enums/Order.enums'

import { OrderMemberPaginatedStateBuilder } from './builders/OrderMemberState.builders'
import { AdditionalServicesT, OptionT, ReceiverT, SenderT } from '@/core/types/common.types'
import { LTLCalendarT, LTLOrderInvoiceCargoAndDeliveryT } from '../types/LTL.types'
import { LTLOrderInvoicesT } from '../types/LTL.types'
import { LTLOrderInvoice } from './LTLOrderInvoice.class'
import { InvoicesRealisation } from '../realisations/Invoices.realisation'
import { EMPTY_ADDITIONAL_SERVICES } from '../constants/common.constants'

export class LTLOrder extends Order {
    private _send_period: OptionT<string> | null

    private _invoices: LTLOrderInvoice[]
    private _selected_receivers_ids: number[]
    private _isSelectReceiver: boolean
    private _isAddInvoice: boolean

    private _isUpdateInvoice: boolean
    private _updatingInvoice: LTLOrderInvoice | null
    private _updatingInvoiceIndex: number | null

    private _isCreateLoading: boolean
    private _isCreated: boolean

    constructor() {
        super(
            OrderTypeEnum.LTL,
            new OrderMemberPaginatedStateBuilder<SenderT[]>([]),
            new OrderMemberPaginatedStateBuilder<ReceiverT[]>([])
        )

        this._send_period = null

        this._invoices = []
        this._selected_receivers_ids = []
        this._isSelectReceiver = true
        this._isAddInvoice = true

        this._isUpdateInvoice = false
        this._updatingInvoice = null
        this._updatingInvoiceIndex = null

        this._isCreateLoading = false
        this._isCreated = false
    }

    // methods
    setDatePayload(date_payload: LTLCalendarT): void {
        if (!date_payload.send_date || !date_payload.send_period) return

        this.setSendDate(date_payload.send_date)
        this._send_period = date_payload.send_period

        this.loadReceivers()
    }

    resetDatePayload(): void {
        this.resetSendDate()
        this._send_period = null
    }

    selectReceiver(receiver: ReceiverT): void {
        this.receiver = receiver
        this._isSelectReceiver = false
    }

    resetReceiver(): void {
        this.receiver = null
        this._isSelectReceiver = true
    }

    startSelectReceiver(): void {
        this._isSelectReceiver = true
    }

    addInvoice(cargo_delivery: LTLOrderInvoiceCargoAndDeliveryT): void {
        if (!this.receiver || !this.receiver.id) return

        const as = { ...EMPTY_ADDITIONAL_SERVICES }

        as.hasGrid = cargo_delivery.services.hasGrid
        as.hasPallet = cargo_delivery.services.hasPallet
        as.hasSoftPackage = cargo_delivery.services.hasSoftPackage

        this._invoices.push(
            new LTLOrderInvoice(
                this.receiver,
                cargo_delivery.delivery,
                cargo_delivery.cargo,
                as,
                cargo_delivery.verify
            )
        )

        this._selected_receivers_ids.push(this.receiver.id)
        this._isAddInvoice = false
    }

    removeInvoice(invoice_index: number): void {
        const deleting_invoice = this._invoices[invoice_index]
        const selectedIndex = this._selected_receivers_ids.findIndex(
            (id: number) => id === deleting_invoice.receiver.id
        )

        this._selected_receivers_ids.splice(selectedIndex, 1)
        this._invoices.splice(invoice_index, 1)

        if (this._invoices.length < 1) {
            this._isAddInvoice = true
            this._selected_receivers_ids = []
        }
    }

    startAddInvoice(): void {
        this._isAddInvoice = true
        this.resetReceiver()
    }

    startUpdateInvoice(invoice_index: number): void {
        this._updatingInvoiceIndex = invoice_index
        this._updatingInvoice = this._invoices[invoice_index]
        this._isUpdateInvoice = true
    }

    stopUpdateInvoice(): void {
        this._isUpdateInvoice = false
        this._updatingInvoiceIndex = null
        this._updatingInvoice = null
    }

    updateInvoice(cargo_delivery: LTLOrderInvoiceCargoAndDeliveryT): void {
        this._invoices[this._updatingInvoiceIndex as number].updateInvoice(cargo_delivery)
        this.stopUpdateInvoice()
    }

    setAdditionalServicesForInvoice(
        invoice_index: number,
        additional_services: AdditionalServicesT
    ): void {
        this._invoices[invoice_index].additional_services = additional_services
    }

    resetAdditionalServicesForInvoice(invoice_index: number): void {
        this._invoices[invoice_index].additional_services = { ...EMPTY_ADDITIONAL_SERVICES }
    }

    async create(): Promise<void> {
        try {
            this._isCreateLoading = true

            if (!this.sender) return

            const receivers = this._invoices.map((invoice) => {
                const receiver = invoice.receiverForCreate

                if (!receiver) return
                if (!this.send_period) return

                return {
                    ...receiver,
                    order_logistics_info: {
                        ...receiver.order_logistics_info,
                        take_date: this.send_date,
                        period_id: this.send_period.id,
                        is_dangerous: invoice.cargo.is_dangerous,
                        invoice_files: invoice.cargo.invoice_files,
                        certificate_of_safety_files: invoice.cargo.certificate_of_safety_files,
                    },
                }
            })

            const createLTLPayload = {
                title: this.sender.title,
                receivers,
                additional_service: this.additional_services,
                sender_id: this.sender.id,
            }

            const invoicesRealisation = new InvoicesRealisation()
            await invoicesRealisation.createLTLOrder(createLTLPayload)

            this._isCreated = true
        } finally {
            this._isCreateLoading = false
        }
    }

    reset() {
        this.resetOrder()
        this.resetDatePayload()

        this._invoices = []
        this._selected_receivers_ids = []
        this._isSelectReceiver = true
        this._isAddInvoice = true
        this._isUpdateInvoice = false
        this._updatingInvoice = null
        this._updatingInvoiceIndex = null

        this._isCreateLoading = false
        this._isCreated = false
    }

    async createExcel(file: File) {
        try {
            this._isCreateLoading = true

            const payload_formdata = new FormData()

            if (!this.sender || !this.sender.title || !this.sender.id) return
            payload_formdata.append('title', this.sender.title)
            payload_formdata.append('sender_id', this.sender.id.toString())

            if (!this.send_date) return
            payload_formdata.append('take_date', this.send_date)

            if (!this._send_period) return
            payload_formdata.append('period_id', this._send_period.id.toString())
            payload_formdata.append('file', file)

            const invoicesRealisation = new InvoicesRealisation()
            await invoicesRealisation.createExcel(payload_formdata)

            this._isCreated = true
        } finally {
            this._isCreateLoading = false
        }
    }

    // getters
    get send_period(): OptionT<string> | null {
        return this._send_period
    }

    get invoices(): LTLOrderInvoicesT {
        return this._invoices
    }

    get is_select_receiver(): boolean {
        return this._isSelectReceiver
    }

    get is_add_invoice(): boolean {
        return this._isAddInvoice
    }

    get is_update_invoice(): boolean {
        return this._isUpdateInvoice
    }

    get updating_invoice(): LTLOrderInvoice | null {
        return this._updatingInvoice
    }

    get isCreateLoading(): boolean {
        return this._isCreateLoading
    }

    get isCreated(): boolean {
        return this._isCreated
    }

    get selected_receivers_ids(): number[] {
        return this._selected_receivers_ids
    }
}
