import { AxiosResponse } from 'axios'
import { http } from '../classes/HTTP.class'
import { PVZGetListParamsT, PVZGetListResponseT, PVZGetResponseT } from '../types/PVZ.types'

const BASE_URL = process.env.VUE_APP_GATEWAY_URL

export class PVZRepository {
    getList(filters: PVZGetListParamsT): Promise<AxiosResponse<PVZGetListResponseT>> {
        const params = {
            ...filters,
            limit: 9999,
        }

        return http.get(`${BASE_URL}/pvz/api/v1/admin/distribution-centers`, { params })
    }

    getPVZ(distribution_center_id: number): Promise<AxiosResponse<PVZGetResponseT>> {
        return http.get(
            `${BASE_URL}/pvz/api/v1/admin/distribution-centers/${distribution_center_id}`
        )
    }
}
