import { ref, Ref, reactive, computed } from 'vue'

import { OrderTypeEnum } from '@/core/enums/Order.enums'
import { FTLOrder } from '@/core/classes/FTLOrder.class'
import { LTLOrder } from '@/core/classes/LTLOrder.class'

import { OptionT, SenderT, ReceiverT, AdditionalServicesT } from '@/core/types/common.types'
import { FTLCalendarT, FTLCarT } from '@/core/types/FTL.types'
import { LTLCalendarT, LTLOrderInvoiceCargoAndDeliveryT } from '@/core/types/LTL.types'

export const CurrentOrderType: Ref<OptionT<string> | null> = ref(null)
export const isFTL = computed(() => CurrentOrderType.value?.value === OrderTypeEnum.FTL)
export const isLTL = computed(() => CurrentOrderType.value?.value === OrderTypeEnum.LTL)
export const isEXPRESS = computed(() => CurrentOrderType.value?.value === OrderTypeEnum.EXPRESS)

export const FTLOrderState = reactive(new FTLOrder())
export const LTLOrderState = reactive(new LTLOrder())

export const useCreateOrder = () => {
    const setOrderType = (order_type: OptionT<string>) => {
        CurrentOrderType.value = order_type
    }

    const openAdditionalServices = () => {
        if (isFTL.value) FTLOrderState.openAdditionalServices()
        if (isLTL.value) LTLOrderState.openAdditionalServices()
    }

    const closeAdditionalServices = () => {
        if (isFTL.value) FTLOrderState.closeAdditionalServices()
        if (isLTL.value) LTLOrderState.closeAdditionalServices()
    }

    const setAdditionalServices = (additional_services: AdditionalServicesT) => {
        if (isFTL.value) FTLOrderState.setAdditionalServices(additional_services)
        if (isLTL.value) LTLOrderState.setAdditionalServices(additional_services)
    }

    const resetAdditionalServices = () => {
        if (isFTL.value) FTLOrderState.resetAdditionalServices()
        if (isLTL.value) LTLOrderState.resetAdditionalServices()
    }

    const loadSenders = () => {
        if (isFTL.value) FTLOrderState.loadSenders()
        if (isLTL.value) LTLOrderState.loadSenders()
    }

    const loadMoreSenders = () => {
        if (isFTL.value) FTLOrderState.loadMoreSenders()
        if (isLTL.value) LTLOrderState.loadMoreSenders()
    }

    const searchSenders = (search_query: string) => {
        if (isFTL.value) FTLOrderState.searchSenders(search_query)
        if (isLTL.value) LTLOrderState.searchSenders(search_query)
    }

    const searchReceivers = (search_query: string) => {
        if (isFTL.value) FTLOrderState.searchReceivers(search_query)
        if (isLTL.value) LTLOrderState.searchReceivers(search_query)
    }

    const selectSender = (sender: SenderT) => {
        if (isFTL.value) FTLOrderState.selectSender(sender)
        if (isLTL.value) LTLOrderState.selectSender(sender)
    }

    const resetSender = () => {
        if (isFTL.value) FTLOrderState.resetSender()
        if (isLTL.value) LTLOrderState.resetSender()
    }

    const setDatePayload = (date_payload: FTLCalendarT | LTLCalendarT) => {
        if (isFTL.value && 'send_time' in date_payload) FTLOrderState.setDatePayload(date_payload)

        if (isLTL.value && 'send_period' in date_payload) {
            LTLOrderState.setDatePayload(date_payload)
            LTLOrderState.openAdditionalServices()
        }
    }

    const resetDatePayload = () => {
        if (isFTL.value) FTLOrderState.resetDatePayload()
        if (isLTL.value) LTLOrderState.resetDatePayload()
    }

    const setFTLCar = (car: FTLCarT) => {
        FTLOrderState.setCar(car)
    }

    const resetFTLCar = () => {
        FTLOrderState.resetCar()
    }

    const loadReceivers = () => {
        if (isFTL.value) FTLOrderState.loadReceivers()
        if (isLTL.value) LTLOrderState.loadReceivers()
    }

    const loadMoreReceivers = () => {
        if (isFTL.value) FTLOrderState.loadMoreReceivers()
        if (isLTL.value) LTLOrderState.loadMoreReceivers()
    }

    const selectReceiver = (receiver: ReceiverT) => {
        if (isFTL.value) FTLOrderState.selectEndPoint(receiver)
        if (isLTL.value) LTLOrderState.selectReceiver(receiver)
    }

    const resetLTLReceiver = () => {
        LTLOrderState.resetReceiver()
    }

    const removeSelectedFTLEndPoint = (end_point_index: number) => {
        FTLOrderState.removeEndPoint(end_point_index)
    }

    const startSelectFTLEndPoint = () => {
        FTLOrderState.startSelectEndPoint()
    }

    const addLTLInvoice = (cargo_delivery: LTLOrderInvoiceCargoAndDeliveryT) => {
        LTLOrderState.addInvoice(cargo_delivery)
    }

    const startUpdateLTLInvoice = (invoice_index: number) => {
        LTLOrderState.startUpdateInvoice(invoice_index)
    }

    const stopUpdateLTLInvoice = () => {
        LTLOrderState.stopUpdateInvoice()
    }

    const updateLTLInvoice = (cargo_delivery: LTLOrderInvoiceCargoAndDeliveryT) => {
        LTLOrderState.updateInvoice(cargo_delivery)
    }

    const removeLTLInvoice = (invoice_index: number) => {
        LTLOrderState.removeInvoice(invoice_index)
    }

    const startAddLTLInvoice = () => {
        LTLOrderState.startAddInvoice()
    }

    const setAdditionalServicesForLTLInvoice = ({
        index,
        additional_services,
    }: {
        index: number
        additional_services: AdditionalServicesT
    }) => {
        LTLOrderState.setAdditionalServicesForInvoice(index, additional_services)
    }

    const resetAdditionalServicesForLTLInvoice = (invoice_index: number) => {
        LTLOrderState.resetAdditionalServicesForInvoice(invoice_index)
    }

    const startSelectLTLReceiver = () => {
        LTLOrderState.startSelectReceiver()
    }

    const create = async () => {
        if (isFTL.value) await FTLOrderState.create()
        if (isLTL.value) await LTLOrderState.create()
    }

    const createByExcel = async (file: File) => {
        await LTLOrderState.createExcel(file)
    }

    const reset = () => {
        if (isFTL.value) FTLOrderState.reset()
        if (isLTL.value) LTLOrderState.reset()
    }

    const removeSender = (sender_id: number) => {
        if (isFTL.value) FTLOrderState.deleteSender(sender_id)
        if (isLTL.value) LTLOrderState.deleteSender(sender_id)
    }

    const removeReceiver = (receiver_id: number) => {
        if (isFTL.value) FTLOrderState.deleteReceiver(receiver_id)
        if (isLTL.value) LTLOrderState.deleteReceiver(receiver_id)
    }

    return {
        searchSenders,
        searchReceivers,
        setOrderType,
        loadSenders,
        loadMoreSenders,
        selectSender,
        resetSender,
        setDatePayload,
        resetDatePayload,
        setFTLCar,
        resetFTLCar,
        loadReceivers,
        loadMoreReceivers,
        selectReceiver,
        resetLTLReceiver,
        removeSelectedFTLEndPoint,
        startSelectFTLEndPoint,
        addLTLInvoice,
        removeLTLInvoice,
        setAdditionalServicesForLTLInvoice,
        resetAdditionalServicesForLTLInvoice,
        create,
        startSelectLTLReceiver,
        startAddLTLInvoice,
        reset,
        openAdditionalServices,
        closeAdditionalServices,
        setAdditionalServices,
        resetAdditionalServices,
        createByExcel,
        removeSender,
        removeReceiver,
        startUpdateLTLInvoice,
        stopUpdateLTLInvoice,
        updateLTLInvoice,
    }
}
