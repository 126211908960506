import { ref, reactive, computed, Ref, unref, toRaw } from 'vue'

import { MemberActionsEnum, MemberTypesEnum } from '@/core/enums/MemberActions.enums'
import {
    SenderT,
    ReceiverT,
    CityT,
    SenderPayloadT,
    ReceiverPayloadT,
} from '@/core/types/common.types'
import { CitiesRealisation } from '@/core/realisations/Cities.realisation'
import { SendersRealisation } from '@/core/realisations/Senders.realisation'
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'

import { ReceiverCreatePayload, SenderCreatePayload } from '@/core/types/Order.types'

import {
    isFTL,
    isLTL,
    FTLOrderState,
    LTLOrderState,
} from '@/modules/CreateOrder/composable/useCreateOrder'

import useNotifications from '@/common/composable/useNotifications'
import { DeliveryRealisation } from '@/core/realisations/Delivery.realisation'
const NOTIFICATIONS_GROUP = 'create-module'

const CurrentMemberAction = ref(MemberActionsEnum.CREATE)
export const isCREATE = computed(() => CurrentMemberAction.value === MemberActionsEnum.CREATE)
export const isUPDATE = computed(() => CurrentMemberAction.value === MemberActionsEnum.UPDATE)

const CurrentMemberType = ref(MemberTypesEnum.SENDER)
export const isSENDER = computed(() => CurrentMemberType.value === MemberTypesEnum.SENDER)
export const isRECEIVER = computed(() => CurrentMemberType.value === MemberTypesEnum.RECEIVER)

export const senderActionState: Ref<SenderT | null> = ref(null)
export const receiverActionState: Ref<ReceiverT | null> = ref(null)

export const isSenderActionsOpened = ref(false)
export const isReceiverActionsOpened = ref(false)

export const isSenderActionsLoading = ref(false)
export const isReceiverActionsLoading = ref(false)

export const senderCities = reactive({
    state: {
        is_loading: false,
    },
    data: [] as CityT[],
})

export const receiverCities = reactive({
    state: {
        is_loading: false,
    },
    data: [] as CityT[],
})

export const useMembersActions = () => {
    const notifications = useNotifications()

    const setMemberAction = (member_action: MemberActionsEnum) => {
        CurrentMemberAction.value = member_action
    }

    const setMemberType = (member_type: MemberTypesEnum) => {
        CurrentMemberType.value = member_type
    }

    const setSenderState = (sender: SenderT) => {
        senderActionState.value = sender
    }

    const resetSenderState = () => {
        senderActionState.value = null
    }

    const setReceiverState = (receiver: ReceiverT) => {
        receiverActionState.value = receiver
    }

    const resetReceiverState = () => {
        receiverActionState.value = null
    }

    const loadSenderCities = async (is_express = false, exact_city_id: number | null = null) => {
        try {
            senderCities.state.is_loading = true

            if (!is_express) {
                const cities = await new CitiesRealisation().getAllCities()
                senderCities.data = cities
            } else {
                if (!exact_city_id) return
                const response = await new DeliveryRealisation().getCities()
                const city = response.data.find(
                    (delivery_city) => delivery_city.id === exact_city_id
                )

                if (!city) return
                senderCities.data = [{ name: city.name, id: city.id, value: city.id, latitude: city.latitude, longitude: city.longitude, coordinates: city.coordinates }]
            }
        } catch (error) {
            console.error(error)
        } finally {
            senderCities.state.is_loading = false
        }
    }

    const loadReceiverCities = async (
        isFTLCities: boolean,
        isLTLCities: boolean,
        sender_city_id: number,
        is_express = false
    ) => {
        try {
            receiverCities.state.is_loading = true

            if (is_express) {
                const response = await new DeliveryRealisation().getCities()
                const city = response.data.find(
                    (delivery_city) => delivery_city.id === sender_city_id
                )

                if (!city) return
                receiverCities.data = [{ name: city.name, id: city.id, value: city.id, latitude: city.latitude, longitude: city.longitude, coordinates: city.coordinates }]
                return
            }

            if (isFTLCities) {
                const cities = await new CitiesRealisation().getAllCities()
                receiverCities.data = cities
                return
            }

            if (isLTLCities && sender_city_id) {
                const cities = await new CitiesRealisation().getCitiesByCityId(sender_city_id)

                receiverCities.data = cities
                return
            }
        } catch (error) {
            console.error(error)
        } finally {
            receiverCities.state.is_loading = false
        }
    }

    const openSenderActions = () => {
        isSenderActionsOpened.value = true
    }

    const closeSenderActions = () => {
        isSenderActionsOpened.value = false
        resetSenderState()
    }

    const openReceiverActions = () => {
        isReceiverActionsOpened.value = true
    }

    const closeReceiverActions = () => {
        isReceiverActionsOpened.value = false
        resetReceiverState()
    }

    const startCreateSender = () => {
        setMemberAction(MemberActionsEnum.CREATE)
        setMemberType(MemberTypesEnum.SENDER)
        openSenderActions()
    }

    const startEditSender = (sender: SenderT) => {
        setMemberAction(MemberActionsEnum.UPDATE)
        setMemberType(MemberTypesEnum.SENDER)
        setSenderState(sender)
        openSenderActions()
    }

    const startCreateReceiver = () => {
        setMemberAction(MemberActionsEnum.CREATE)
        setMemberType(MemberTypesEnum.RECEIVER)
        openReceiverActions()
    }

    const startEditReceiver = (receiver: ReceiverT) => {
        setMemberAction(MemberActionsEnum.UPDATE)
        setMemberType(MemberTypesEnum.SENDER)
        setReceiverState(receiver)
        openReceiverActions()
    }

    const getSenderActionStateRaw = (): SenderT | null => {
        return toRaw(unref(senderActionState))
    }

    const getReceiverActionStateRaw = (): ReceiverT | null => {
        return toRaw(unref(receiverActionState))
    }

    const createSender = async (sender_payload: SenderCreatePayload, is_express = false) => {
        try {
            isSenderActionsLoading.value = true

            const senderPayload: SenderPayloadT = {
                title: sender_payload.title,
                entity: sender_payload.title,
                city_id: sender_payload.city.id,
                full_address: sender_payload.full_address,
                latitude: sender_payload.latitude,
                longitude: sender_payload.longitude,
                full_name: sender_payload.full_name,
                phone: sender_payload.phone,
                additional_phone: sender_payload.additional_phone,
                office: sender_payload.office,
                comment: sender_payload.comment,
                index: sender_payload.index,
                house: sender_payload.house,
                street: sender_payload.street,
                warehouse_id: sender_payload.warehouse_id,
            }

            const sendersRealisation = new SendersRealisation()
            const response = await sendersRealisation.create(senderPayload)

            if (is_express) {
                return
            }

            if (isFTL.value) {
                FTLOrderState.senders.data.unshift(response.data)
                return
            }

            if (isLTL.value) {
                LTLOrderState.senders.data.unshift(response.data)
                return
            }
        } finally {
            isSenderActionsLoading.value = false
        }
    }

    const createReceiver = async (receiver_payload: ReceiverCreatePayload, is_express = false) => {
        try {
            isReceiverActionsLoading.value = true

            const receiverPayload: ReceiverPayloadT = {
                title: receiver_payload.title,
                entity: receiver_payload.title,
                city_id: receiver_payload.city.id,
                full_address: receiver_payload.full_address,
                latitude: receiver_payload.latitude,
                longitude: receiver_payload.longitude,
                full_name: receiver_payload.full_name,
                phone: receiver_payload.phone,
                additional_phone: receiver_payload.additional_phone,
                office: receiver_payload.office,
                comment: receiver_payload.comment,
                index: receiver_payload.index,
                house: receiver_payload.house,
                street: receiver_payload.street,
                warehouse_id: receiver_payload.warehouse_id,
                distribution_center_id: receiver_payload.distribution_center_id,
            }

            const receiversRealisation = new ReceiversRealisation()
            const response = await receiversRealisation.create(receiverPayload)

            if (is_express) {
                return
            }

            if (isFTL.value) {
                FTLOrderState.receivers.data.unshift(response.data)
                return
            }

            if (isLTL.value) {
                LTLOrderState.receivers.data.unshift(response.data)
                return
            }
        } catch (error) {
            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка создания получателя',
                },
                error
            )
        } finally {
            isReceiverActionsLoading.value = false
        }
    }

    const updateSender = async (
        sender_id: number,
        sender_payload: SenderCreatePayload,
        is_express = false
    ) => {
        try {
            isSenderActionsLoading.value = true

            const senderPayload: SenderPayloadT = {
                id: sender_id,
                title: sender_payload.title,
                entity: sender_payload.title,
                city_id: sender_payload.city.id,
                full_address: sender_payload.full_address,
                latitude: sender_payload.latitude,
                longitude: sender_payload.longitude,
                full_name: sender_payload.full_name,
                phone: sender_payload.phone,
                additional_phone: sender_payload.additional_phone,
                office: sender_payload.office,
                comment: sender_payload.comment,
                index: sender_payload.index,
                house: sender_payload.house,
                street: sender_payload.street,
                warehouse_id: sender_payload.warehouse_id,
            }

            const sendersRealisation = new SendersRealisation()
            await sendersRealisation.update(sender_id, senderPayload)

            if (is_express) {
                return
            }

            if (isFTL.value) {
                const findedSenderIndex = FTLOrderState.senders.data.findIndex(
                    (sender: SenderT) => sender.id === sender_id
                )

                if (findedSenderIndex >= 0)
                    FTLOrderState.senders.data[findedSenderIndex] = senderPayload
                return
            }

            if (isLTL.value) {
                const findedSenderIndex = LTLOrderState.senders.data.findIndex(
                    (sender: SenderT) => sender.id === sender_id
                )

                if (findedSenderIndex >= 0)
                    LTLOrderState.senders.data[findedSenderIndex] = senderPayload
                return
            }
        } finally {
            isSenderActionsLoading.value = false
        }
    }

    const updateReceiver = async (
        receiver_id: number,
        receiver_payload: ReceiverCreatePayload,
        is_express = false
    ) => {
        try {
            isReceiverActionsLoading.value = true

            const receiverPayload: ReceiverPayloadT = {
                id: receiver_id,
                title: receiver_payload.title,
                entity: receiver_payload.title,
                city_id: receiver_payload.city.id,
                full_address: receiver_payload.full_address,
                latitude: receiver_payload.latitude,
                longitude: receiver_payload.longitude,
                full_name: receiver_payload.full_name,
                phone: receiver_payload.phone,
                additional_phone: receiver_payload.additional_phone,
                office: receiver_payload.office,
                comment: receiver_payload.comment,
                index: receiver_payload.index,
                house: receiver_payload.house,
                street: receiver_payload.street,
                warehouse_id: receiver_payload.warehouse_id,
                distribution_center_id: receiver_payload.distribution_center_id,
            }

            const receiversRealisation = new ReceiversRealisation()
            await receiversRealisation.update(receiver_id, receiverPayload)

            if (is_express) {
                return
            }

            if (isFTL.value) {
                const findedReceiverIndex = FTLOrderState.receivers.data.findIndex(
                    (receiver: ReceiverT) => receiver.id === receiver_id
                )

                if (findedReceiverIndex >= 0)
                    FTLOrderState.receivers.data[findedReceiverIndex] = receiverPayload
                return
            }

            if (isLTL.value) {
                const findedReceiverIndex = LTLOrderState.receivers.data.findIndex(
                    (receiver: ReceiverT) => receiver.id === receiver_id
                )

                if (findedReceiverIndex >= 0)
                    LTLOrderState.receivers.data[findedReceiverIndex] = receiverPayload
                return
            }
        } finally {
            isReceiverActionsLoading.value = false
        }
    }

    const updateReceiverLTL = async (
        receiver_id: number,
        receiver_payload: ReceiverCreatePayload,
        is_express = false
    ) => {
        try {
            isReceiverActionsLoading.value = true

            const receiverPayload: ReceiverPayloadT = {
                id: receiver_id,
                title: receiver_payload.title,
                entity: receiver_payload.title,
                city_id: receiver_payload.city.id,
                full_address: receiver_payload.full_address,
                latitude: receiver_payload.latitude,
                longitude: receiver_payload.longitude,
                full_name: receiver_payload.full_name,
                phone: receiver_payload.phone,
                additional_phone: receiver_payload.additional_phone,
                office: receiver_payload.office,
                comment: receiver_payload.comment,
                index: receiver_payload.index,
                house: receiver_payload.house,
                street: receiver_payload.street,
                warehouse_id: receiver_payload.warehouse_id,
                distribution_center_id: receiver_payload.distribution_center_id,
            }

            const receiversRealisation = new ReceiversRealisation()
            await receiversRealisation.updateLTL(receiver_id, receiverPayload)

            if (is_express) {
                return
            }

            if (isFTL.value) {
                const findedReceiverIndex = FTLOrderState.receivers.data.findIndex(
                    (receiver: ReceiverT) => receiver.id === receiver_id
                )

                if (findedReceiverIndex >= 0)
                    FTLOrderState.receivers.data[findedReceiverIndex] = receiverPayload
                return
            }

            if (isLTL.value) {
                const findedReceiverIndex = LTLOrderState.receivers.data.findIndex(
                    (receiver: ReceiverT) => receiver.id === receiver_id
                )

                if (findedReceiverIndex >= 0)
                    LTLOrderState.receivers.data[findedReceiverIndex] = receiverPayload
                return
            }
        } finally {
            isReceiverActionsLoading.value = false
        }
    }

    return {
        startCreateSender,
        startEditSender,
        startCreateReceiver,
        startEditReceiver,
        closeSenderActions,
        closeReceiverActions,
        getSenderActionStateRaw,
        getReceiverActionStateRaw,
        loadSenderCities,
        loadReceiverCities,
        createSender,
        updateSender,
        createReceiver,
        updateReceiver,
        updateReceiverLTL,
    }
}
