import { WarehousesRepository } from '../repositories/Warehouses.repository'
import { WarehousesGetByCityIdResponseT } from '../types/Responses/Warehouses.responses'

const warehousesRepository = new WarehousesRepository()

const warehousesService = {
    async getByCityId(city_id: number): Promise<WarehousesGetByCityIdResponseT> {
        const response = await warehousesRepository.getByCityId(city_id)
        return response.data
    },
}

export default warehousesService
