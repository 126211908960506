
import { defineComponent, PropType } from 'vue'

import { AdditionalServicesT } from '@/core/types/common.types'
import { AdditionalServiceBilling } from '@/new-services/types/billing'

export default defineComponent({
    props: {
        blocked: {
            type: Boolean,
            default: false,
        },
        additionalServices: {
            type: [Object, null] as PropType<AdditionalServicesT | null>,
            default: null,
        },
        additionalServicesBilling: {
            type: Array as PropType<AdditionalServiceBilling[]>,
            default: () => [],
        },
    },
    emits: ['reset'],
    setup(props, { emit }) {
        const emitReset = () => {
            emit('reset')
        }

        const getValue = (service_name: string, key: keyof AdditionalServiceBilling) => {
            const service = props.additionalServicesBilling.find((s) => s.name === service_name)
            if (!service) return '-'
            return service[key]
        }

        return { emitReset, getValue }
    },
})
