import { PVZI } from '../interfaces/PVZ.interface'
import { PVZGetListParamsT, PVZGetListResponseT, PVZGetResponseT } from '../types/PVZ.types'
import PVZService from '../services/PVZ.service'

export class PVZRealisation implements PVZI {
    getList(params: PVZGetListParamsT): Promise<PVZGetListResponseT> {
        return PVZService.getList(params)
    }

    getPVZ(distribution_center_id: number): Promise<PVZGetResponseT> {
        return PVZService.getPVZ(distribution_center_id)
    }
}
