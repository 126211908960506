// Constants
import { PHONE_OPTIONS } from '@/core/constants/Phone.constants'

export const showFile = (content: File | Blob) => {
    const url = window.URL.createObjectURL(content)
    window.open(url, '_blank')
}

export const showFileByUrl = (url: string) => {
    window.open(url, '_blank')
}

export const detectOptionByPhoneNumber = (phone_number: string) => {
    const code = phone_number.split('-')[1]
    const option = PHONE_OPTIONS.find(
        (option) =>
            code[0] === option.phone_code.toString()[0] &&
            code.includes(option.phone_code.toString())
    )

    return option
}

export const cutName = (name: string, max_length: number): string => {
    if (!name) return '-'

    if (name && name.length > max_length) {
        let name_parts = name.split('')
        name_parts = name_parts.splice(0, max_length - 1)
        name_parts.push('...')
        return name_parts.join('')
    }

    return name
}
