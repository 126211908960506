import { RequestT } from '@/core/types/common.types'

export class RequestStateBuilder implements RequestT {
    public is_done: boolean
    public is_success: boolean
    public is_failed: boolean
    public is_loading: boolean
    public response_status: number

    constructor() {
        this.is_done = false
        this.is_success = false
        this.is_failed = false
        this.is_loading = false
        this.response_status = 0
    }
}
