import { required, maxLength } from '@vuelidate/validators'

export const CreateSenderValidation = {
    title: { required, maxLength: maxLength(255) },
    full_address: { required },
    city: { required },
    full_name: { required, maxLength: maxLength(128) },
    phone: { required },
    additional_phone: { required },
    office: { maxLength: maxLength(50) },
    index: { maxLength: maxLength(50) },
}
