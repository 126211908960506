import { MapsRepository } from '../repositories/Maps.repository'
import {
    GisFormattedListItem,
    GisFormattedResponseT,
    GisGeocodeResponseResultItem,
    YandexFormattedListItem,
    YandexFormattedResponseT,
    YandexGeoObjectT,
    YandexMetaDataAddressComponentT,
} from '../types/Map.types'

const mapsRepository = new MapsRepository()

const getCoordsFromYandexGeocodeResponse = (yandex_response: string) => {
    const coords = yandex_response.split(' ')
    const latitude = Number(coords[1])
    const longitude = Number(coords[0])

    return [latitude, longitude]
}

const getFormattedResponseFromYandexGeoObject = (
    yandexGeoObject: YandexGeoObjectT
): YandexFormattedResponseT => {
    const address_components =
        yandexGeoObject.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components

    const object_address_components: Record<string, string> = {}

    address_components.forEach((component: YandexMetaDataAddressComponentT) => {
        object_address_components[component.kind] = component.name
    })

    const formatted_response: YandexFormattedResponseT = {
        full_address: yandexGeoObject.GeoObject.metaDataProperty.GeocoderMetaData.text,
        country: object_address_components.country || null,
        province: object_address_components.province || null,
        locality: object_address_components.locality || null,
        street: object_address_components.street || null,
        house: object_address_components.house || null,
    }

    return formatted_response
}

const getFormattedResponseFromGisItem = (
    gis_item: GisGeocodeResponseResultItem
): GisFormattedResponseT => {
    let street = null as string | null
    if (gis_item.address_name) {
        const parts = gis_item.address_name.split(',')
        street = parts[0]
    }

    const formatted_response: GisFormattedResponseT = {
        full_address: gis_item.full_name,
        province: null,
        street,
        country: null,
        house: null,
        locality: null,
    }

    if (gis_item.full_address_name) formatted_response.full_address = gis_item.full_address_name

    return formatted_response
}

const mapsService = {
    async getYandexAdressByCoords(coords: number[]): Promise<YandexFormattedResponseT | null> {
        const response = await mapsRepository.geocodeAddressByCoords(coords)
        const first_feature_member = response.data.response.GeoObjectCollection.featureMember[0]

        if (!first_feature_member) return null
        return getFormattedResponseFromYandexGeoObject(first_feature_member)
    },
    async getYandexCoordsByAddress(address: string): Promise<number[] | null> {
        const response = await mapsRepository.geocodeCoordsByAddress(address)
        const first_member = response.data.response.GeoObjectCollection.featureMember[0]

        if (!response) return null

        const formatted_coords = getCoordsFromYandexGeocodeResponse(
            first_member.GeoObject.Point.pos
        )
        return formatted_coords
    },
    async getListByAddress(address: string): Promise<YandexFormattedListItem[]> {
        const response = await mapsRepository.geocodeCoordsByAddress(address)
        const feature_members = response.data.response.GeoObjectCollection.featureMember

        if (!feature_members || !feature_members.length) return []

        const mapped_data: YandexFormattedListItem[] = feature_members.map(
            (d: YandexGeoObjectT, index: number) => {
                const formatted_response = getFormattedResponseFromYandexGeoObject(d)

                return {
                    id: index,
                    name: d.GeoObject.name,
                    description: d.GeoObject.description,
                    full_address: formatted_response.full_address,
                    points: getCoordsFromYandexGeocodeResponse(d.GeoObject.Point.pos),
                    street: formatted_response.street,
                    house: formatted_response.house,
                }
            }
        )

        return mapped_data
    },
    async geocodeAddressListByGis(address: string): Promise<GisFormattedListItem[]> {
        const response = await mapsRepository.geocodeAddressListByGis(address)

        const mappedResponse = response.data.result.items.map((item: any, index: number) => {
            const points = [item.point.lat, item.point.lon]

            let country = '-'
            let city = '-'
            let hint = '-'
            let street = null as string | null

            if (item.adv_div && item.adv_div.length) {
                item.adm_div.forEach((div: any) => {
                    if (div.type === 'country') country = div.name
                    if (div.type === 'city') city = div.name
                })

                if (country === '-') country = item.adm_div[0].name
                if (city === '-') city = item.adm_div[item.adm_div.length - 1].name

                hint = `${country}, ${city}`
            } else {
                hint = item.name
            }

            if (item.address_name) {
                const parts = item.address_name.split(',')
                street = parts[0]
            }

            return {
                id: index,
                name: item.full_name || `${item.address_name}, ${item.name}`,
                full_address: item.full_name || `${item.address_name}, ${item.name}`,
                description: hint,
                points,
                // TODO
                house: null,
                street,
            }
        })

        return mappedResponse
    },

    async geocodeAddressByGisCoords(coords: number[]): Promise<GisFormattedResponseT | null> {
        const response = await mapsRepository.geocodeAddressByGisCoords(coords)
        const first_item = response.data.result.items[0]

        if (!first_item) return null

        const formatted_response = getFormattedResponseFromGisItem(first_item)
        return formatted_response
    },
}

export default mapsService
