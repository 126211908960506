
import { defineComponent, PropType } from 'vue'

import iEdit from '@/assets/icons/Edit.svg'
import iPerson from '@/assets/icons/Person.svg'
import iLocation from '@/assets/icons/Location.svg'

import { MemberT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        'icon-edit': iEdit,
        'icon-person': iPerson,
        'icon-location': iLocation,
    },
    props: {
        isFTL: {
            type: Boolean,
            default: false,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
        isExpress: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Отправитель',
        },
        point: {
            type: Object as PropType<MemberT>,
            default: null,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reset'],
    setup(_, { emit }) {
        const emitReset = () => {
            emit('reset')
        }

        return { emitReset }
    },
})
