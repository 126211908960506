import { PVZRepository } from '../repositories/PVZ.repository'
import { PVZGetListParamsT, PVZGetListResponseT, PVZGetResponseT } from '../types/PVZ.types'

const pvzRepository = new PVZRepository()

const PVZService = {
    async getList(params: PVZGetListParamsT): Promise<PVZGetListResponseT> {
        const response = await pvzRepository.getList(params)
        return response.data
    },

    async getPVZ(distribution_center_id: number): Promise<PVZGetResponseT> {
        const response = await pvzRepository.getPVZ(distribution_center_id)
        return response.data
    },
}

export default PVZService
