
import { defineComponent, PropType } from 'vue'

import SButton from '@/common/components/SButton/index.vue'

import iEdit from '@/assets/icons/Edit.svg'
import iPerson from '@/assets/icons/Person.svg'
import iLocation from '@/assets/icons/Location.svg'

import { SenderT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        's-button': SButton,
        'icon-edit': iEdit,
        'icon-person': iPerson,
        'icon-location': iLocation,
    },
    props: {
        isFTL: {
            type: Boolean,
            default: false,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
        isEXPRESS: {
            type: Boolean,
            default: false,
        },
        point: {
            type: Object as PropType<SenderT>,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
        isSender: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['select', 'edit', 'delete'],
    setup(props, { emit }) {
        const emitSelect = () => {
            emit('select', props.point)
        }

        const emitEdit = () => {
            emit('edit', props.point)
        }

        const emitDelete = () => {
            const answer = confirm(`Вы уверены что хотите удалить пункт (${props.point.title})?`)
            if (!answer) return

            emit('delete', props.point.id)
        }

        return { emitSelect, emitEdit, emitDelete }
    },
})
