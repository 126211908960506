
import { defineComponent } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    components: {
        's-button': SButton,
    },
    props: {
        isUpdate: {
            type: Boolean,
            default: false,
        },
        orderId: {
            type: String,
            default: null,
        },
    },
    emits: ['reset'],
    setup(props, { emit }) {
        const router = useRouter()

        const goInInvoices = () => {
            router.push('/history')
            emit('reset')
        }

        const goInOrder = () => {
            router.push(`/order/ltl/${props.orderId}`)
            emit('reset')
        }

        const emitReset = () => {
            emit('reset')
        }

        return { goInInvoices, goInOrder, emitReset }
    },
})
