import { ReceiverT, AdditionalServicesT } from '@/core/types/common.types'
import {
    LTLOrderInvoiceDeliveryT,
    LTLOrderInvoiceCargoT,
    LTLOrderPayloadReceiverT,
    LTLOrderInvoiceCargoAndDeliveryT,
} from '@/core/types/LTL.types'

export class LTLOrderInvoice {
    private _receiver: ReceiverT
    public delivery: LTLOrderInvoiceDeliveryT
    public cargo: LTLOrderInvoiceCargoT
    public additional_services: AdditionalServicesT
    public verify: number | null

    constructor(
        receiver: ReceiverT,
        delivery: LTLOrderInvoiceDeliveryT,
        cargo: LTLOrderInvoiceCargoT,
        additional_services: AdditionalServicesT,
        verify: number | null
    ) {
        this._receiver = receiver
        this.delivery = delivery
        this.cargo = cargo
        this.verify = verify

        this.additional_services = additional_services
    }

    updateInvoice(cargo_delivery: LTLOrderInvoiceCargoAndDeliveryT): void {
        this.cargo = { ...cargo_delivery.cargo }
        this.delivery = { ...cargo_delivery.delivery }

        this.additional_services.hasGrid = cargo_delivery.services.hasGrid
        this.additional_services.hasPallet = cargo_delivery.services.hasPallet
        this.additional_services.hasSoftPackage = cargo_delivery.services.hasSoftPackage

        this.verify = cargo_delivery.verify
        // this.delivery.cargo_name = cargo_delivery.delivery.cargo_name
        // this.delivery.codPayment = cargo_delivery.delivery.codPayment
        // this.delivery.declaredPrice = cargo_delivery.delivery.declaredPrice
        // this.delivery.payer_company_id = cargo_delivery.delivery.payer_company_id
        // this.delivery.paymentMethod = cargo_delivery.delivery.paymentMethod
        // this.delivery.paymentType = cargo_delivery.delivery.paymentType
        // this.delivery.shipmentType = cargo_delivery.delivery.shipmentType

        // this.cargo.annotation = cargo_delivery.cargo.annotation
        // this.cargo.customerInvoiceNumber = cargo_delivery.cargo.customerInvoiceNumber
        // this.cargo.height = cargo_delivery.cargo.height
        // this.cargo.isReturnDocuments = cargo_delivery.cargo.isReturnDocuments
        // this.cargo.length = cargo_delivery.cargo.length
        // this.cargo.places = cargo_delivery.cargo.places
    }

    get receiver(): ReceiverT {
        return this._receiver
    }

    get receiverForCreate(): LTLOrderPayloadReceiverT | null {
        if (!this._receiver.id) return null

        const payload: LTLOrderPayloadReceiverT = {
            receiver_id: this._receiver.id,
            additional_service: this.additional_services,
            order_logistics_info: {
                verify: this.verify,
                product_name: this.cargo.product_name,
                dop_invoice_number: this.cargo.customerInvoiceNumber,
                places: this.cargo.places,
                volume: this.cargo.volume,
                weight: this.cargo.weight,
                width: this.cargo.width,
                height: this.cargo.height,
                depth: this.cargo.length,
                annotation: this.cargo.annotation,
                payment_type: this.delivery.paymentType.value,
                payment_method: this.delivery.paymentMethod.value,
                shipment_type: this.delivery.shipmentType.value,
                cod_payment: this.delivery.codPayment,
                cargo_name: this.delivery.cargo_name,
                declared_price: this.delivery.declaredPrice,
                period_id: 0,
                should_return_document: this.cargo.isReturnDocuments ? 1 : 0,
                take_date: '',
                temperature_regime_type_id: this.cargo.temperature_regime_type_id,
            },
        }

        if (this.delivery.payer_company_id)
            payload.order_logistics_info.payer_company_id = this.delivery.payer_company_id

        return payload
    }
}
