import { OptionT } from '../types/common.types'

export const LTL_TOKEN = 'LTL'
export const LTL_ORDER_TYPE = { id: 2, name: 'LTL', value: LTL_TOKEN }
export const COMPANY_SPARK_DIRECTION_ID = 1018

export const PERIOD_OPTIONS: OptionT<number>[] = [
    {
        id: 1,
        name: 'До обеда (8:00 - 12:00)',
        value: 1,
    },
    {
        id: 2,
        name: 'После обеда (12:00 - 18:00)',
        value: 2,
    },
]

export const PERIOD_AFTERNOON_OPTIONS: OptionT<number>[] = [
    {
        id: 2,
        name: 'После обеда (12:00 - 18:00)',
        value: 2,
    },
]

export const LTL_INVOICE_STATUSES = [
    {
        id: 1,
        name: 'Заказ создан',
        value: 1,
    },
    {
        id: 2,
        name: 'Забирается курьером',
        value: 2,
    },
    {
        id: 3,
        name: 'Груз забран у отправителя',
        value: 3,
    },
    {
        id: 5,
        name: 'Обработка груза на складе',
        value: 5,
    },
    {
        id: 6,
        name: 'Груз обработан и ожидает отправку',
        value: 6,
    },
    {
        id: 7,
        name: 'Груз в пути',
        value: 7,
    },
    {
        id: 8,
        name: 'Груз в городе назначения',
        value: 8,
    },
    {
        id: 9,
        name: 'Доставляется курьером',
        value: 9,
    },
    {
        id: 10,
        name: 'Доставлен',
        value: 10,
    },
    {
        id: 4,
        name: 'Забор груза отменен',
        value: 4,
    },
]

export const LTL_STATUS_WAITING_TOKEN = 'waiting'
export const LTL_STATUS_IN_PROGRESS_TOKEN = 'in_progress'
export const LTL_STATUS_COMPLETED_TOKEN = 'completed'
export const LTL_STATUS_CANCELED_TOKEN = 'canceled'
export const LTL_SHIPMENT_TYPE_MESSAGE =
    'Дорогой клиент, если ваш груз содержит товары, относящиеся к опасным грузам, проставьте соответствующую галочку и прикрепите документы. Для обеспечения безопасности перевозок все грузы авиа подлежат обязательному досмотру.'
