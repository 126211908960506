
import { defineComponent, PropType } from 'vue'

// import iEdit from '@/assets/icons/Edit.svg'
import { LTLOrderInvoiceDeliveryT } from '@/core/types/LTL.types'

export default defineComponent({
    props: {
        delivery: {
            type: Object as PropType<LTLOrderInvoiceDeliveryT>,
            default: null,
        },
    },
})
