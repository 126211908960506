import { PhoneOption } from '../types/Phone.types'

// Resources
// https://gist.github.com/mikemunsie/d58d88cad0281e4b187b0effced769b2 - masks
// https://countrycode.org/ - countries

export const PHONE_OPTIONS: PhoneOption[] = [
    {
        emoji: 'aze.png',
        code: 'AZE',
        country: 'Азербайджан',
        mask: '+994-##-###-##-##',
        phone_code: 994,
    },
    {
        emoji: 'arm.png',
        code: 'ARM',
        country: 'Армения',
        mask: '+374-##-###-###',
        phone_code: 374,
    },
    {
        emoji: 'blr.png',
        code: 'BLR',
        country: 'Белоруссия',
        mask: '+375-##-###-##-##',
        phone_code: 375,
    },
    {
        emoji: 'esp.png',
        code: 'ES',
        country: 'Испания',
        mask: '+34-###-###-###',
        phone_code: 34,
    },
    {
        emoji: 'kz.png',
        code: 'KZ',
        country: 'Казахстан',
        mask: '+7-###-###-##-##',
        phone_code: 7,
    },
    {
        emoji: 'cn.png',
        code: 'CN',
        country: 'Китай',
        mask: '+86-###-####-####',
        phone_code: 86,
    },
    {
        emoji: 'kgz.png',
        code: 'KGZ',
        country: 'Кыргызстан',
        mask: '+996-###-###-###',
        phone_code: 996,
    },
    {
        emoji: 'mda.png',
        code: 'MDA',
        country: 'Молдавия',
        mask: '+373-####-####',
        phone_code: 373,
    },
    {
        emoji: 'ru.png',
        code: 'RU',
        country: 'Россия',
        mask: '+7-###-###-##-##',
        phone_code: 7,
    },
    {
        emoji: 'tja.png',
        code: 'TJA',
        country: 'Таджикистан',
        mask: '+992-##-###-####',
        phone_code: 992,
    },
    {
        emoji: 'tkm.png',
        code: 'TKM',
        country: 'Туркменистан',
        mask: '+993-#-###-####',
        phone_code: 993,
    },
    {
        emoji: 'tur.png',
        code: 'TUR',
        country: 'Турция',
        mask: '+90-###-###-####',
        phone_code: 90,
    },
    {
        emoji: 'uzb.png',
        code: 'UZB',
        country: 'Узбекистан',
        mask: '+998-##-###-####',
        phone_code: 998,
    },
    {
        emoji: 'ukr.png',
        code: 'UKR',
        country: 'Украина',
        mask: '+380-##-###-##-##',
        phone_code: 380,
    },
]
