import { Order } from './Order.class'
import { OrderTypeEnum } from '../enums/Order.enums'

import { OrderMemberPaginatedStateBuilder } from './builders/OrderMemberState.builders'
import { ReceiverT, SenderT } from '@/core/types/common.types'
import { FTLCalendarT, FTLCarT, FTLEndPointT, FTLEndPointsT } from '../types/FTL.types'
import { FTLCar } from './FTLCar.class'
import { InvoicesRealisation } from '../realisations/Invoices.realisation'

export class FTLOrder extends Order {
    private _send_time: string | null
    private _car: FTLCarT | null

    private _isSelectEndPoint: boolean
    private _end_points: FTLEndPointsT
    private _selected_end_points_ids: number[]

    private _isCreateLoading: boolean
    private _isCreated: boolean

    constructor() {
        super(
            OrderTypeEnum.FTL,
            new OrderMemberPaginatedStateBuilder<SenderT[]>([]),
            new OrderMemberPaginatedStateBuilder<ReceiverT[]>([])
        )

        this._send_time = null
        this._car = null

        this._isSelectEndPoint = true
        this._end_points = []
        this._selected_end_points_ids = []

        this._isCreated = false
        this._isCreateLoading = false
    }

    // methods
    setDatePayload(date_payload: FTLCalendarT): void {
        if (!date_payload.send_date || !date_payload.send_time) return

        this.setSendDate(date_payload.send_date)
        this._send_time = date_payload.send_time
    }

    resetDatePayload(): void {
        this.resetSendDate()
        this._send_time = null
    }

    setCar(car: FTLCarT): void {
        this._car = new FTLCar(car)
        this.loadReceivers()
    }

    resetCar(): void {
        this._car = null
    }

    selectEndPoint(receiver: FTLEndPointT): void {
        if (!receiver.id) return

        this._end_points.push(receiver)
        this._selected_end_points_ids.push(receiver.id)
        this._isSelectEndPoint = false
    }

    startSelectEndPoint(): void {
        this._isSelectEndPoint = true
    }

    removeEndPoint(end_point_index: number): void {
        const deleting_end_point = this._end_points[end_point_index]
        const selectedIndex = this._selected_end_points_ids.findIndex(
            (id: number) => id === deleting_end_point.id
        )

        this._selected_end_points_ids.splice(selectedIndex, 1)
        this._end_points.splice(end_point_index, 1)

        if (this._end_points.length < 1) {
            this._isSelectEndPoint = true
            this._selected_end_points_ids = []
        }
    }

    async create(): Promise<void> {
        try {
            this._isCreateLoading = true

            if (!this.sender) return
            if (!this.car) return

            const receivers = this._end_points.map((end_point, index) => {
                return {
                    receiver_id: end_point.id,
                    number: index,
                    is_endpoint: index === 0 ? 1 : 0,
                }
            })

            const car_info = {
                cubature: this.car.cubicCapacity,
                temperature_mode: this.car.temperatureRegim,
                loading_type: this.car.loadingType.name,
                oversized_cargo: this.car.isOversized ? 1 : 0,
                load_capacity: this.car.liftingCapacity.load_capacity,
                annotation: this.car.annotation,
                payment_method: this.car.paymentMethod.value,
                declared_price: this.car.declaredPrice,
                dop_invoice_number: this.car.customerInvoiceNumber,
            }

            const createFTLPayload = {
                sender_id: this.sender.id,
                take_time: this.send_time,
                take_date: this.send_date,
                car_info,
                receivers,
                additional_service: this.additional_services,
            }

            const invoicesRealisation = new InvoicesRealisation()
            await invoicesRealisation.createFTLOrder(createFTLPayload)

            this._isCreated = true
        } finally {
            this._isCreateLoading = false
        }
    }

    reset(): void {
        this.resetOrder()
        this.resetDatePayload()
        this.resetCar()
        this._isSelectEndPoint = true
        this._end_points = []
        this._selected_end_points_ids = []
        this._isCreateLoading = false
        this._isCreated = false
    }

    // getters
    get send_time(): string | null {
        return this._send_time
    }

    get car(): FTLCarT | null {
        return this._car
    }

    get is_select_end_point(): boolean {
        return this._isSelectEndPoint
    }

    get end_points(): FTLEndPointsT {
        return this._end_points
    }

    get isCreateLoading(): boolean {
        return this._isCreateLoading
    }

    get isCreated(): boolean {
        return this._isCreated
    }

    get selected_end_points_ids(): number[] {
        return this._selected_end_points_ids
    }
}
