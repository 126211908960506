
import { defineComponent, PropType, computed } from 'vue'

import OrderPoint from '@/modules/CreateOrder/components/OrderPoint/index.vue'
import CompletedCalendar from '@/modules/CreateOrder/components/Completed/CompletedCalendar/index.vue'
import CompletedFTLCar from '@/modules/CreateOrder/components/Completed/CompletedFTLCar/index.vue'
import CompletedServices from '@/modules/CreateOrder/components/Completed/CompletedServices/index.vue'

import iSettingsAdd from '@/assets/icons/SettingsAdd.svg'
// import iTrash from '@/assets/icons/Trash.svg'

import { AdditionalServicesT, OptionT, SenderT } from '@/core/types/common.types'
import { FTLCarT } from '@/core/types/FTL.types'
import { AdditionalServiceBilling } from '@/new-services/types/billing'

export default defineComponent({
    components: {
        'order-point': OrderPoint,
        'completed-calendar': CompletedCalendar,
        'completed-ftl-car': CompletedFTLCar,
        'completed-services': CompletedServices,
        'icon-settings-add': iSettingsAdd,
        // 'icon-trash': iTrash,
    },
    props: {
        isFTL: {
            type: Boolean,
            default: false,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
        FTLcar: {
            type: Object as PropType<FTLCarT>,
            default: null,
        },
        sender: {
            type: Object as PropType<SenderT>,
            default: null,
        },
        sendDate: {
            type: String,
            default: null,
        },
        sendTime: {
            type: String,
            default: null,
        },
        sendPeriod: {
            type: [String, Object] as PropType<OptionT<number> | string>,
            default: null,
        },
        additionalServices: {
            type: [Object, null] as PropType<AdditionalServicesT | null>,
            default: null,
        },
        blocked: {
            type: Boolean,
            default: false,
        },
        isAdditionalServicesSetted: {
            type: Boolean,
            default: false,
        },
        additionalServicesBilling: {
            type: Array as PropType<AdditionalServiceBilling[]>,
            default: () => [],
        },
    },
    emits: [
        'reset-sender',
        'reset-calendar',
        'reset-ftl-car',
        'reset-additional-services',
        'open-additional-services',
    ],
    setup(props, { emit }) {
        const emitResetSender = () => {
            emit('reset-sender')
        }

        const emitResetCalendar = () => {
            emit('reset-calendar')
        }

        const emitResetFTLCar = () => {
            emit('reset-ftl-car')
        }

        const emitResetAdditionalServices = () => {
            emit('reset-additional-services')
        }

        const emitOpenAdditionalServices = () => {
            emit('open-additional-services')
        }

        const isCanShowAdditionalServices = computed(() => {
            if (!props.isAdditionalServicesSetted || !props.additionalServices) return false

            if (
                props.additionalServices.hasCar ||
                props.additionalServices.hasSoftPackage ||
                props.additionalServices.hasManipulator ||
                props.additionalServices.hasCrane ||
                props.additionalServices.hasHydraulicTrolley ||
                props.additionalServices.hasGrid ||
                props.additionalServices.hasLoader ||
                props.additionalServices.hasPallet
            ) {
                return true
            }

            return false
        })

        return {
            emitResetSender,
            emitResetCalendar,
            emitResetFTLCar,
            emitResetAdditionalServices,
            emitOpenAdditionalServices,
            isCanShowAdditionalServices,
        }
    },
})
