import DeliveryI from '../interfaces/Delivery.interface'
import { DeliveryService } from '../services/Delivery.service'
import { DeliveryOrderPayloadT, DeliveryPointT, NewOrderPayload } from '../types/Delivery.types'
import { ExpressQueryFilters } from '../types/History.types'
import {
    DeliveryGetCitiesResponseT,
    DeliveryGetOrderResponseT,
    DeliveryGetOrdersResponseT,
    DeliveryGetTariffsResponseT,
    DeliveryGetTasksResponseT,
    DeliveryRefreshStatusResponseT,
    DeliveryTrackResponseT,
} from '../types/Responses/Delivery.responses'

const deliveryService = new DeliveryService()

export class DeliveryRealisation implements DeliveryI {
    async getCities(): Promise<DeliveryGetCitiesResponseT> {
        const response = await deliveryService.getCities()
        return response
    }

    async getTariffs(coordinates: DeliveryPointT[]): Promise<DeliveryGetTariffsResponseT> {
        const response = await deliveryService.getTariffs(coordinates)
        return response
    }

    async createExpressOrder(payload: NewOrderPayload): Promise<void> {
        const response = await deliveryService.createExpressOrder(payload)
        return response
    }

    async getExpressOrders(params: ExpressQueryFilters): Promise<DeliveryGetOrdersResponseT> {
        const response = await deliveryService.getExpressOrders(params)
        return response
    }

    async getExpressOrder(order_id: number): Promise<DeliveryGetOrderResponseT> {
        const response = await deliveryService.getExpressOrder(order_id)
        return response
    }

    async cancelOrder(id: number): Promise<void> {
        const response = await deliveryService.cancelOrder(id)
        return response
    }

    async refreshStatus(order_id: number): Promise<DeliveryRefreshStatusResponseT> {
        const response = await deliveryService.refreshStatus(order_id)
        return response
    }

    async getTasks(page: number): Promise<DeliveryGetTasksResponseT> {
        const response = await deliveryService.getTasks(page)
        return response
    }

    async getTrackInformation(order_id: number): Promise<DeliveryTrackResponseT> {
        const response = await deliveryService.getTrackInformation(order_id)
        return response
    }
}
